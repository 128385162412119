<template>
  <v-row class="align-center mt-3">
    <div class="ml-3 mr-5 text-caption grey--text">努力値</div>
    <v-col cols="7" class="pt-1">
      <v-combobox
        v-model="_effortValue"
        :items="[0, 4, 252]"
        type="number"
        hide-details
        class="pt-0"
      />
    </v-col>
    <v-btn icon small @click="minus"><v-icon>mdi-minus</v-icon></v-btn>
    <v-btn icon small @click="plus"><v-icon>mdi-plus</v-icon></v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    effortValue: {
      type: Number,
      required: true,
    },
  },

  computed: {
    _effortValue: {
      get() {
        return this.effortValue;
      },
      set(value) {
        this.$emit("update:effortValue", Number(value));
      },
    },
  },

  methods: {
    plus() {
      if (this._effortValue < 4) {
        this._effortValue = 4;
      } else if (this._effortValue < 252) {
        this._effortValue =
          (Math.floor((this._effortValue - 4) / 8) + 1) * 8 + 4;
      } else this._effortValue = 252;
    },
    minus() {
      if (this._effortValue > 244) {
        this._effortValue = 244;
      } else if (this._effortValue > 4) {
        this._effortValue =
          (Math.floor((this._effortValue - 4) / 8) - 1) * 8 + 4;
      } else this._effortValue = 0;
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
