<template>
  <v-container>
    当サイトではポケモン対戦に便利なツールやミニゲームを公開しています。

    <v-divider class="my-4 dashed" />

    <v-row>
      <v-col cols="12" sm="6">
        <v-card to="/speed" class="pt-2">
          <v-img height="200" :src="speedImage" contain />
          <v-card-title class="text-h6">ポケモンSVすばやさ比較</v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card to="/party" class="pt-2">
          <v-img height="200" :src="partyImage" contain />
          <v-card-title class="text-h6"
            >ポケモンパーティ文字起こし</v-card-title
          >
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card to="/spinda" class="pt-2">
          <v-img height="200" :src="spindaImage" contain />
          <v-card-title class="text-h6">パッチール神経衰弱</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import speedImage from "@/assets/speed.png";
// import soonImage from "@/assets/soon.png";
import partyImage from "@/assets/sample_party.png";
import spindaImage from "@/assets/spinda.jpg";
export default {
  metaInfo: {
    title: "ヤドンの井戸",
    titleTemplate: null,
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "ポケモン対戦用の便利ツール集です。対戦中に簡単にすばやさ比較ができるツールなどを公開中。",
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://poketool.mega-yadoran.jp/",
      },
      { vmid: "og:title", property: "og:title", content: "ヤドンの井戸" },
      { vmid: "og:type", property: "og:type", content: "website" },
      {
        vmid: "og:description",
        property: "og:description",
        content: "ポケモン対戦用の便利ツール集",
      },
      {
        vmid: "og:site_name",
        property: "og:site_name",
        content: "ヤドンの井戸",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://poketool.mega-yadoran.jp/logo.png",
      },
    ],
  },
  created() {
    this.speedImage = speedImage;
    // this.soonImage = soonImage;
    this.partyImage = partyImage;
    this.spindaImage = spindaImage;
  },
};
</script>
