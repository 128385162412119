<template>
  <v-container class="mb-4">
    <div class="text-h5 text-center">ポケモンパーティ文字起こし</div>

    <v-divider class="my-2 dashed" />
    <v-row justify="center" class="text-body-2">
      <v-col cols="11" sm="10">
        <div class="my-4">
          <v-icon>mdi-help-circle</v-icon>このツールについて
        </div>
        <div>
          ポケモンSVのパーティデータを構築記事のテンプレートなどに変換するツールです。<br />
          レンタルチームの画像を読み込むと一部のデータを自動入力できます。<br />
          データを入力後、ページ下部の「変換」ボタンを押してください。
        </div>
      </v-col>
      <v-col cols="11" sm="10">
        <HowToUseForHatena :open.sync="openForHatenaDialog" />
      </v-col>
    </v-row>
    <v-divider class="my-6 dashed" />

    <v-row class="mb-4">
      <v-col cols="12">
        <ImageDropArea
          :loading="loading"
          :imageUrl="imageUrl"
          :api-error="apiError"
          @on-drop="onDrop"
        />
      </v-col>
      <div class="d-flex mx-auto">
        <v-checkbox v-model="isForArticle" label="詳細入力" />
        <v-checkbox
          v-model="needIndividualValue"
          label="個体値を入力"
          :disabled="!isForArticle"
          class="ml-4"
        />
      </div>

      <v-col cols="12" class="mx-auto">
        <v-row class="d-flex justify-space-around">
          <v-col
            v-for="i in 6"
            :key="i"
            cols="12"
            sm="6"
            class="my-2 px-4 px-sm-2"
          >
            <PokemonInputField
              :pokemon.sync="pokemon[i - 1]"
              :is-for-article="isForArticle"
              :need-individual-value="needIndividualValue"
              ref="pokemonInputField"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ConvertBtnArea
      :convert-type.sync="convertType"
      :pokemon="pokemon"
      :convert-success-tooltip.sync="convertSuccessTooltip"
      @convert="convert"
    />

    <v-row class="my-4">
      <v-col cols="12" class="text-center">↓↓↓ </v-col>
      <ResultArea
        ref="result"
        :convert-type="convertType"
        :pokemon="pokemon"
        :is-for-article="isForArticle"
        :need-individual-value="needIndividualValue"
      />
    </v-row>

    <div class="text-center my-4 text-body-2">
      <HowToUseForHatena :open.sync="openForHatenaDialog" />
    </div>

    <ForFirstUsageDialog :open.sync="openForFirstUsageDialog" />
    <LoadingDialog :open.sync="loading" />
  </v-container>
</template>

<script>
import axios from "axios";
// import { pokemonNames } from "../../data/present";
import * as Save from "../../utils/save";
import ImageDropArea from "./page/Party2Text/ImageDropArea";
import PokemonInputField from "./page/Party2Text/PokemonInputField";
import ConvertBtnArea from "./page/Party2Text/ConvertBtnArea";
import ResultArea from "./page/Party2Text/ResultArea";
import HowToUseForHatena from "./page/Party2Text/HowToUseForHatena";
import ForFirstUsageDialog from "./page/Party2Text/ForFirstUsageDialog";
import LoadingDialog from "./page/Party2Text/LoadingDialog";

import howToImage1 from "@/assets/how_to_1.jpg";
import samplePartyImage from "@/assets/sample_party.png";

export default {
  components: {
    ImageDropArea,
    PokemonInputField,
    ConvertBtnArea,
    ResultArea,
    HowToUseForHatena,
    ForFirstUsageDialog,
    LoadingDialog,
  },

  data: () => ({
    loading: false,
    image: null,
    imageUrl: "",
    apiError: false,
    isForArticle: true,
    needIndividualValue: false,
    openForHatenaDialog: false,
    openForFirstUsageDialog: false,
    pokemon: [],
    convertType: 0,
    convertSuccessTooltip: false,
    mockData: [
      {
        name: { value: "サザンドラ", error: false, focused: false },
        ability: { value: "ふゆう", error: false, focused: false },
        item: { value: "こだわりメガネ", error: false, focused: false },
        moves: [
          { value: "りゅうせいぐん", error: false, focused: false },
          { value: "あくのはどう", error: false, focused: false },
          { value: "だいもんじ", error: false, focused: false },
          { value: "ラスターカノン", error: false, focused: false },
        ],
        nature: { value: "おくびょう", error: false, focused: false },
        tera: { value: "はがね", error: false, focused: false },
        isA0: true,
        ev: [
          { value: 4, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 252, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 252, error: false, focused: false },
        ],
        iv: [
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
        ],
      },
      {
        name: { value: "メタモン", error: false, focused: false },
        ability: { value: "かわりもの", error: false, focused: false },
        item: { value: "こだわりスカーフ", error: false, focused: false },
        moves: [
          { value: "へんしん", error: false, focused: false },
          { value: "", error: false, focused: false },
          { value: "", error: false, focused: false },
          { value: "", error: false, focused: false },
        ],
        nature: { value: "なまいき", error: false, focused: false },
        tera: { value: "フェアリー", error: false, focused: false },
        isA0: false,
        ev: [
          { value: 252, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 4, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 252, error: false, focused: false },
          { value: null, error: false, focused: false },
        ],
        iv: [
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
        ],
      },
      {
        name: { value: "キラフロル", error: false, focused: false },
        ability: { value: "どくげしょう", error: false, focused: false },
        item: { value: "きあいのタスキ", error: false, focused: false },
        moves: [
          { value: "マジカルシャイン", error: false, focused: false },
          { value: "マッドショット", error: false, focused: false },
          { value: "キラースピン", error: false, focused: false },
          { value: "ステルスロック", error: false, focused: false },
        ],
        nature: { value: "おくびょう", error: false, focused: false },
        tera: { value: "フェアリー", error: false, focused: false },
        isA0: true,
        ev: [
          { value: 4, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 252, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 252, error: false, focused: false },
        ],
        iv: [
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
        ],
      },
      {
        name: { value: "ウェーニバル", error: false, focused: false },
        ability: { value: "じしんかじょう", error: false, focused: false },
        item: { value: "ゴツゴツメット", error: false, focused: false },
        moves: [
          { value: "アクアステップ", error: false, focused: false },
          { value: "アンコール", error: false, focused: false },
          { value: "みがわり", error: false, focused: false },
          { value: "はねやすめ", error: false, focused: false },
        ],
        nature: { value: "しんちょう", error: false, focused: false },
        tera: { value: "くさ", error: false, focused: false },
        isA0: false,
        ev: [
          { value: 244, error: false, focused: false },
          { value: 4, error: false, focused: false },
          { value: 76, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 116, error: false, focused: false },
          { value: 68, error: false, focused: false },
        ],
        iv: [
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
        ],
      },
      {
        name: { value: "ラウドボーン", error: false, focused: false },
        ability: { value: "てんねん", error: false, focused: false },
        item: { value: "オボンのみ", error: false, focused: false },
        moves: [
          { value: "フレアソング", error: false, focused: false },
          { value: "たたりめ", error: false, focused: false },
          { value: "あくび", error: false, focused: false },
          { value: "なまける", error: false, focused: false },
        ],
        nature: { value: "ずぶとい", error: false, focused: false },
        tera: { value: "ノーマル", error: false, focused: false },
        isA0: true,
        ev: [
          { value: 252, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 188, error: false, focused: false },
          { value: 4, error: false, focused: false },
          { value: 60, error: false, focused: false },
          { value: 4, error: false, focused: false },
        ],
        iv: [
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
        ],
      },
      {
        name: { value: "カイリュー", error: false, focused: false },
        ability: { value: "マルチスケイル", error: false, focused: false },
        item: { value: "たべのこし", error: false, focused: false },
        moves: [
          { value: "しんそく", error: false, focused: false },
          { value: "じしん", error: false, focused: false },
          { value: "りゅうのまい", error: false, focused: false },
          { value: "はねやすめ", error: false, focused: false },
        ],
        nature: { value: "いじっぱり", error: false, focused: false },
        tera: { value: "ノーマル", error: false, focused: false },
        isA0: false,
        ev: [
          { value: 212, error: false, focused: false },
          { value: 204, error: false, focused: false },
          { value: 4, error: false, focused: false },
          { value: null, error: false, focused: false },
          { value: 4, error: false, focused: false },
          { value: 84, error: false, focused: false },
        ],
        iv: [
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
          { value: 31, error: false, focused: false },
        ],
      },
    ],
  }),

  metaInfo: {
    title: "ポケモンパーティ文字起こし",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "ポケモンSVのパーティデータを構築記事のテンプレートなどに変換できるツールです。レンタルチームの画像から文字起こしができます。",
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://poketool.mega-yadoran.jp/speed",
      },
      { vmid: "og:title", property: "og:title", content: "ヤドンの井戸" },
      { vmid: "og:type", property: "og:type", content: "website" },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "ポケモンSVのパーティデータを構築記事のテンプレートなどに変換できるツールです。レンタルチームの画像から文字起こしができます。",
      },
      {
        vmid: "og:site_name",
        property: "og:site_name",
        content: "ポケモンパーティ文字起こし - ヤドンの井戸",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://poketool.mega-yadoran.jp/logo.png",
      },
    ],
    link: [
      { rel: "preload", as: "image", href: howToImage1 },
      { rel: "preload", as: "image", href: samplePartyImage },
    ],
  },

  created() {
    this.initializePokemonList();
    // this.focusFirstEmpty();
    // this.pokemon = this.mockData;
  },

  methods: {
    initializePokemonList() {
      const emptyValue = { value: "", error: false, focused: false };
      const emptyPokemon = {
        name: emptyValue,
        ability: emptyValue,
        item: emptyValue,
        moves: Array(4).fill(emptyValue),
        nature: emptyValue,
        tera: emptyValue,
        isA0: false,
        ev: Array(6).fill({ value: null, error: false, focused: false }),
        iv: Array(6).fill({ value: 31, error: false, focused: false }),
      };
      this.pokemon = Array.from({ length: 6 }, () =>
        JSON.parse(JSON.stringify(emptyPokemon))
      );
    },

    onDrop(image) {
      const existText = (() => {
        let flag = false;
        this.pokemon.forEach((item) => {
          if (item.name.value) {
            flag = true;
          }
        });
        return flag;
      })();
      if (
        !existText ||
        window.confirm("入力中のデータは破棄されます。よろしいですか？")
      ) {
        this.loading = true;
        this.initializePokemonList();
        this.$refs.result.clear();

        this.image = image;
        this.imageUrl = URL.createObjectURL(image);
        this.callApi();
      }
    },

    callApi() {
      this.apiError = false;
      const formData = new FormData();
      formData.append("file", this.image);
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL + "/image-recognition",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.data.error === false) {
            this.pokemon = this.pokemon.map((p, i) => {
              const converted = this.format(res.data.result[i]);
              return Object.assign(p, converted);
            });
          } else {
            throw `API Error: ${res.data.message}`;
          }

          this.loading = false;

          // console.log(res);
        })
        .catch(() => {
          this.apiError = true;
          this.image = null;
          this.imageUrl = "";

          this.loading = false;
          // console.log(err);
        });
    },

    format(pokemon) {
      return {
        name: { value: pokemon.name, error: false, focused: false },
        ability: { value: pokemon.ability, error: false, focused: false },
        item: { value: pokemon.item, error: false, focused: false },
        tera: { value: pokemon.tera, error: false, focused: false },
        moves: pokemon.moves.map((move) => ({
          value: move,
          error: false,
          focused: false,
        })),
      };
    },

    focus(index, target) {
      this.$refs.pokemonInputField[index].focus(target);
    },

    focusFirstEmpty() {
      setTimeout(() => {
        this.focus(0, "name");
      }, 100);
    },

    convert() {
      const success = this.$refs.result.convert();
      if (success) {
        if (Save.isFirstUseParty2Text()) {
          this.openForFirstUsageDialog = true;
          Save.saveFirstUsage();
        } else {
          this.convertSuccessTooltip = true;
          setTimeout(() => {
            this.convertSuccessTooltip = false;
          }, 1000);
        }
      }
    },
  },
};
</script>
