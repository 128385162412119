<template>
  <v-card
    class="grey lighten-3 mx-auto px-2 d-flex flex-column text-center fill-height"
    width="90%"
    max-width="640"
    align-content="center"
    :ripple="false"
    @click="onClick"
    @keypress.enter="onClick"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
  >
    <v-responsive :aspect-ratio="16 / 9">
      <v-img
        v-if="imageUrl !== ''"
        class="mx-auto"
        :src="imageUrl"
        contain
        :max-width="`${0.9 * $vuetify.breakpoint.width}px`"
      />
      <div v-else class="fill-height d-flex align-center">
        <div v-if="apiError" class="ma-auto">
          <div class="error--text text-caption text-sm-body-1 text-left">
            画像の読み取りに失敗しました。<br />
            もう一度お試しください。<br />
            何度も失敗する場合は「使える画像」<br />
            の説明をご確認ください。
          </div>
          <AvailableImageDialog />
        </div>
        <div v-else class="ma-auto">
          <div>ここに画像をドラッグ＆ドロップ</div>
          <div class="text-caption my-1">または</div>
          <div><span class="info--text">タップ</span>して画像を選択</div>
          <AvailableImageDialog />
        </div>
      </div>
    </v-responsive>
    <input
      type="file"
      accept="image/*"
      class="image-input-form"
      style="display: none"
      @change="onSelect"
    />
  </v-card>
</template>

<script>
import AvailableImageDialog from "./AvailableImageDialog";

export default {
  components: {
    AvailableImageDialog,
  },

  props: {
    loading: { type: Boolean, required: true },
    imageUrl: { type: String, required: true },
    apiError: { type: Boolean, required: true },
  },

  methods: {
    // ドラッグ＆ドロップ時にファイルを取得して親のイベントを発火
    onDrop(e) {
      if (this.loading) return;
      const image = e.dataTransfer.files[0];
      this.$emit("on-drop", image);
    },

    // ボタンクリック時にファイル選択ダイアログを開く
    async onClick() {
      if (this.loading) return;
      const input = document.querySelector(".image-input-form");
      input.click();
    },

    // ファイル選択時に親のイベントを発火
    onSelect(e) {
      if (this.loading) return;
      const image = e.target.files[0];
      if (!image) return;
      this.$emit("on-drop", image);
    },
  },
};
</script>
