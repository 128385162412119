export const roman2katakana = (value) => {
  value = value.replace(/-/g, "ー");
  value = value.replace(/la/g, "ァ");
  value = value.replace(/li/g, "ィ");
  value = value.replace(/lu/g, "ゥ");
  value = value.replace(/le/g, "ェ");
  value = value.replace(/lo/g, "ォ");
  value = value.replace(/lya/g, "ャ");
  value = value.replace(/lyu/g, "ュ");
  value = value.replace(/lyo/g, "ョ");
  value = value.replace(/lwa/g, "ヮ");
  value = value.replace(/ltu/g, "ッ");
  value = value.replace(/ltsu/g, "ッ");
  value = value.replace(/xa/g, "ァ");
  value = value.replace(/xi/g, "ィ");
  value = value.replace(/xu/g, "ゥ");
  value = value.replace(/xe/g, "ェ");
  value = value.replace(/xo/g, "ォ");
  value = value.replace(/xya/g, "ャ");
  value = value.replace(/xyu/g, "ュ");
  value = value.replace(/xyo/g, "ョ");
  value = value.replace(/xwa/g, "ヮ");
  value = value.replace(/xtu/g, "ッ");
  value = value.replace(/xtsu/g, "ッ");
  value = value.replace(/gg/g, "ッg");
  value = value.replace(/kk/g, "ッk");
  value = value.replace(/ss/g, "ッs");
  value = value.replace(/zz/g, "ッz");
  value = value.replace(/jj/g, "ッj");
  value = value.replace(/cc/g, "ッc");
  value = value.replace(/tt/g, "ッt");
  value = value.replace(/dd/g, "ッd");
  value = value.replace(/hh/g, "ッh");
  value = value.replace(/ff/g, "ッf");
  value = value.replace(/vv/g, "ッv");
  value = value.replace(/bb/g, "ッb");
  value = value.replace(/pp/g, "ッp");
  value = value.replace(/mm/g, "ッm");
  value = value.replace(/yy/g, "ッy");
  value = value.replace(/rr/g, "ッr");
  value = value.replace(/ww/g, "ッw");
  value = value.replace(/nnn/g, "ンn");
  value = value.replace(/nn/g, "ン");
  value = value.replace(/ga/g, "ガ");
  value = value.replace(/gi/g, "ギ");
  value = value.replace(/gu/g, "グ");
  value = value.replace(/ge/g, "ゲ");
  value = value.replace(/go/g, "ゴ");
  value = value.replace(/za/g, "ザ");
  value = value.replace(/zi/g, "ジ");
  value = value.replace(/ji/g, "ジ");
  value = value.replace(/zu/g, "ズ");
  value = value.replace(/ze/g, "ゼ");
  value = value.replace(/zo/g, "ゾ");
  value = value.replace(/da/g, "ダ");
  value = value.replace(/di/g, "ヂ");
  value = value.replace(/du/g, "ヅ");
  value = value.replace(/de/g, "デ");
  value = value.replace(/do/g, "ド");
  value = value.replace(/ba/g, "バ");
  value = value.replace(/bi/g, "ビ");
  value = value.replace(/bu/g, "ブ");
  value = value.replace(/be/g, "ベ");
  value = value.replace(/bo/g, "ボ");
  value = value.replace(/pa/g, "パ");
  value = value.replace(/pi/g, "ピ");
  value = value.replace(/pu/g, "プ");
  value = value.replace(/pe/g, "ペ");
  value = value.replace(/po/g, "ポ");
  value = value.replace(/vu/g, "ヴ");
  value = value.replace(/va/g, "ヴァ");
  value = value.replace(/vi/g, "ヴィ");
  value = value.replace(/ve/g, "ヴェ");
  value = value.replace(/vo/g, "ヴォ");
  value = value.replace(/fa/g, "ファ");
  value = value.replace(/fi/g, "フィ");
  value = value.replace(/fe/g, "フェ");
  value = value.replace(/fo/g, "フォ");
  value = value.replace(/kya/g, "キャ");
  value = value.replace(/kyu/g, "キュ");
  value = value.replace(/kyo/g, "キョ");
  value = value.replace(/sha/g, "シャ");
  value = value.replace(/shu/g, "シュ");
  value = value.replace(/sho/g, "ショ");
  value = value.replace(/sya/g, "シャ");
  value = value.replace(/syu/g, "シュ");
  value = value.replace(/syo/g, "ショ");
  value = value.replace(/cha/g, "チャ");
  value = value.replace(/chu/g, "チュ");
  value = value.replace(/cho/g, "チョ");
  value = value.replace(/tya/g, "チャ");
  value = value.replace(/tyu/g, "チュ");
  value = value.replace(/tyo/g, "チョ");
  value = value.replace(/nya/g, "ニャ");
  value = value.replace(/nyu/g, "ニュ");
  value = value.replace(/nyo/g, "ニョ");
  value = value.replace(/hya/g, "ヒャ");
  value = value.replace(/hyu/g, "ヒュ");
  value = value.replace(/hyo/g, "ヒョ");
  value = value.replace(/mya/g, "ミャ");
  value = value.replace(/myu/g, "ミュ");
  value = value.replace(/myo/g, "ミョ");
  value = value.replace(/rya/g, "リャ");
  value = value.replace(/ryu/g, "リュ");
  value = value.replace(/ryo/g, "リョ");
  value = value.replace(/gya/g, "ギャ");
  value = value.replace(/gyu/g, "ギュ");
  value = value.replace(/gyo/g, "ギョ");
  value = value.replace(/ja/g, "ジャ");
  value = value.replace(/ju/g, "ジュ");
  value = value.replace(/jo/g, "ジョ");
  value = value.replace(/zya/g, "ジャ");
  value = value.replace(/zyu/g, "ジュ");
  value = value.replace(/zyo/g, "ジョ");
  value = value.replace(/dya/g, "ヂャ");
  value = value.replace(/dyu/g, "ヂュ");
  value = value.replace(/dyo/g, "ヂョ");
  value = value.replace(/bya/g, "ビャ");
  value = value.replace(/byu/g, "ビュ");
  value = value.replace(/byo/g, "ビョ");
  value = value.replace(/pya/g, "ピャ");
  value = value.replace(/pyu/g, "ピュ");
  value = value.replace(/pyo/g, "ピョ");
  value = value.replace(/ka/g, "カ");
  value = value.replace(/ki/g, "キ");
  value = value.replace(/ku/g, "ク");
  value = value.replace(/ke/g, "ケ");
  value = value.replace(/ko/g, "コ");
  value = value.replace(/ta/g, "タ");
  value = value.replace(/ti/g, "チ");
  value = value.replace(/chi/g, "チ");
  value = value.replace(/tu/g, "ツ");
  value = value.replace(/tsu/g, "ツ");
  value = value.replace(/te/g, "テ");
  value = value.replace(/to/g, "ト");
  value = value.replace(/sa/g, "サ");
  value = value.replace(/si/g, "シ");
  value = value.replace(/shi/g, "シ");
  value = value.replace(/su/g, "ス");
  value = value.replace(/se/g, "セ");
  value = value.replace(/so/g, "ソ");
  value = value.replace(/na/g, "ナ");
  value = value.replace(/ni/g, "ニ");
  value = value.replace(/nu/g, "ヌ");
  value = value.replace(/ne/g, "ネ");
  value = value.replace(/no/g, "ノ");
  value = value.replace(/ma/g, "マ");
  value = value.replace(/mi/g, "ミ");
  value = value.replace(/mu/g, "ム");
  value = value.replace(/me/g, "メ");
  value = value.replace(/mo/g, "モ");
  value = value.replace(/ra/g, "ラ");
  value = value.replace(/ri/g, "リ");
  value = value.replace(/ru/g, "ル");
  value = value.replace(/re/g, "レ");
  value = value.replace(/ro/g, "ロ");
  value = value.replace(/wa/g, "ワ");
  value = value.replace(/wo/g, "ヲ");
  value = value.replace(/ya/g, "ヤ");
  value = value.replace(/yu/g, "ユ");
  value = value.replace(/yo/g, "ヨ");
  value = value.replace(/ha/g, "ハ");
  value = value.replace(/hi/g, "ヒ");
  value = value.replace(/hu/g, "フ");
  value = value.replace(/fu/g, "フ");
  value = value.replace(/he/g, "ヘ");
  value = value.replace(/ho/g, "ホ");
  value = value.replace(/a/g, "ア");
  value = value.replace(/i/g, "イ");
  value = value.replace(/u/g, "ウ");
  value = value.replace(/e/g, "エ");
  value = value.replace(/o/g, "オ");
  value = value.replace(/n/g, "ン");

  return value;
};

export const hiragana2katakana = (value) => {
  value = value.replace(/[\u3041-\u3096]/g, function (match) {
    var chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
  return value;
};

export const convert2katakana = (value) => {
  value = roman2katakana(value);
  value = hiragana2katakana(value);
  return value;
};
