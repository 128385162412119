var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-2 mb-4"},[_c('div',{staticClass:"text-h5 text-center mb-4"},[_vm._v("パッチール神経衰弱")]),(_vm.isCleared && !_vm.isPlaying)?_c('ClearDialog',{attrs:{"counter":_vm.counter,"level":_vm.level},on:{"restart":_vm.restart}}):(!_vm.isPlaying)?_c('v-card',{staticClass:"mx-auto px-3 px-md-4 py-6 text-center",attrs:{"width":"90%","max-width":"500","color":"white"}},[_c('SpindaSvg',{attrs:{"pid":_vm.generatePid()}}),_c('div',{staticClass:"flex-center"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}},[_c('v-radio',{attrs:{"label":"かんたん","value":0}}),_c('v-radio',{attrs:{"label":"むずかしい","value":1}})],1)],1),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.start}},[_vm._v("START")])],1):_c('div',[_c('v-card',{staticClass:"text-center pa-4 mx-auto mb-4",attrs:{"width":"240"}},[_c('div',{staticClass:"text-h6"},[_vm._v("経過時間")]),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.counter)+" 秒")])]),_c('v-row',{staticClass:"px-2"},_vm._l((_vm.pids),function(pid,i){return _c('v-col',{key:`col-${i}`,staticClass:"d-flex justify-center align-center",class:_vm.selected.includes(i)
            ? 'spinda_col__selected'
            : _vm.corrected.includes(i)
            ? 'spinda_col__hidden'
            : 'spinda_col',attrs:{"cols":"3"},on:{"click":function($event){return _vm.select(i)}}},[(
            _vm.level === 1 && !_vm.corrected.includes(i) && !_vm.selected.includes(i)
          )?_c('SpindaBlack'):_c('SpindaSvg',{attrs:{"pid":pid}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }