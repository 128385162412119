<template>
  <v-btn-toggle v-model="_natureType" class="nature-type-toggle">
    <v-btn
      v-for="t in natureTypes"
      :key="t"
      :value="t"
      :color="natureType === t ? 'grey darken-1 ' : ''"
      :ripple="false"
      class="mx-0 px-0"
      ><v-icon :color="natureType === t ? 'white' : ''" small>{{
        t
      }}</v-icon></v-btn
    >
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    natureType: {
      type: String,
      required: true,
    },
    natureTypes: {
      type: Object,
      required: true,
    },
  },

  computed: {
    _natureType: {
      get() {
        return this.natureType;
      },
      set(value) {
        this.$emit("update:natureType", value);
      },
    },
  },
};
</script>

<style>
.nature-type-toggle .v-btn {
  height: 28px !important;
  min-width: 40px !important;
}
</style>
