<template>
  <v-row class="align-center">
    <div class="ml-3 mr-5 text-caption grey--text">実数値</div>
    <v-col cols="7" class="pt-1">
      <v-combobox v-model="_realValue" type="text" hide-details class="pt-0" />
    </v-col>
    <v-btn icon small @click="minus"><v-icon>mdi-minus</v-icon></v-btn>
    <v-btn icon small @click="plus"><v-icon>mdi-plus</v-icon></v-btn>
  </v-row>
</template>

<script>
export default {
  props: {
    realValue: {
      type: Number,
      required: true,
    },
  },

  computed: {
    _realValue: {
      get() {
        return this.realValue;
      },
      set(value) {
        this.$emit("update:realValue", Number(value));
      },
    },
  },

  methods: {
    plus() {
      if (this._realValue < 0) {
        this._realValue = 0;
      } else this._realValue++;
    },

    minus() {
      if (this._realValue < 0) {
        this._realValue = 0;
      } else this._realValue--;
    },
  },
};
</script>
