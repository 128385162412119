<template>
  <v-card class="mx-sm-auto mx-2" max-width="500" outlined elevation="1">
    <v-simple-table class="mx-auto result-table">
      <tbody>
        <template v-for="(r, i) in result">
          <tr v-if="r.array.length > 0" :key="i">
            <td class="text-center">
              <v-chip :color="r.color" :class="r.class">{{ r.label }}</v-chip>
            </td>
            <td class="text-center text-body-1 py-3">
              <div v-for="(item, j) in r.array" :key="j">
                <v-row>
                  <v-col cols="2" sm="3" />
                  <v-col cols="4" sm="4" class="px-0">{{ item.label }}</v-col>
                  <v-col cols="4" sm="2" class="px-0">({{ item.value }})</v-col>
                </v-row>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import { calcSpeed, calcStatus } from "../../../../utils/calc";
import { data } from "../../../../data/present";

export default {
  props: {
    yourSpeed: {
      type: Number,
      required: true,
    },

    opponent: {
      type: String,
      required: true,
    },
    opponentStates: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.pokemonData = data;
  },

  computed: {
    opponentSpeedBase: {
      get() {
        const p = this.pokemonData.find((po) => po[0] === this.opponent);
        if (!p) return 0;
        return p[6];
      },
      set() {},
    },

    result: {
      get() {
        const tailwindRatio = this.opponentStates[0].value ? 2.0 : 1.0;
        const paralysisRatio = this.opponentStates[1].value ? 0.5 : 1.0;
        const ratioArray = [tailwindRatio, paralysisRatio];
        const status252 = calcStatus(this.opponentSpeedBase, 252);
        const status4 = calcStatus(this.opponentSpeedBase, 4);
        const status0 = calcStatus(this.opponentSpeedBase, 0);

        const opponentSpeedArray = [
          {
            label: "最速 +1",
            value: calcSpeed([status252, 1.1, 1.5, ...ratioArray]),
          },
          {
            label: "準速 +1",
            value: calcSpeed([status252, 1.5, ...ratioArray]),
          },
          {
            label: "最速",
            value: calcSpeed([status252, 1.1, ...ratioArray]),
          },
          {
            label: "準速",
            value: calcSpeed([status252, ...ratioArray]),
          },
          {
            label: "4振り",
            value: calcSpeed([status4, ...ratioArray]),
          },
          {
            label: "無振り",
            value: calcSpeed([status0, ...ratioArray]),
          },
        ];
        return [
          {
            label: "抜けない",
            color: "error",
            class: "",
            array: opponentSpeedArray
              .filter((o) => o.value > this.yourSpeed)
              .sort((a, b) => b.value - a.value),
          },
          {
            label: "同速",
            color: "orange",
            class: "px-6 white--text",
            array: [
              ...opponentSpeedArray,
              {
                label: "自分側",
                value: this.yourSpeed,
              },
            ]
              .filter((o) => o.value === this.yourSpeed)
              .sort((a, b) => b.value - a.value),
          },
          {
            label: "抜ける",
            color: "success",
            class: "px-5",
            array: opponentSpeedArray
              .filter((o) => o.value < this.yourSpeed)
              .sort((a, b) => b.value - a.value),
          },
        ];
      },
      set() {},
    },
  },
};
</script>

<style lang="scss">
.result-table {
  tr > td:first-child {
    background: #eeeeee;
    width: 40%;
  }

  tr > td:last-child {
    background-color: #fff !important;
  }
}
</style>
