<template>
  <v-container class="mt-2 mb-4">
    <div class="text-h5 text-center mb-4">パッチール神経衰弱</div>

    <ClearDialog
      v-if="isCleared && !isPlaying"
      :counter="counter"
      :level="level"
      @restart="restart"
    />
    <v-card
      v-else-if="!isPlaying"
      width="90%"
      max-width="500"
      color="white"
      class="mx-auto px-3 px-md-4 py-6 text-center"
    >
      <SpindaSvg :pid="generatePid()" />

      <div class="flex-center">
        <v-radio-group v-model="level" row>
          <v-radio label="かんたん" :value="0"></v-radio>
          <v-radio label="むずかしい" :value="1"></v-radio>
        </v-radio-group>
      </div>

      <v-btn color="success" @click="start">START</v-btn>
    </v-card>
    <div v-else>
      <v-card width="240" class="text-center pa-4 mx-auto mb-4">
        <div class="text-h6">経過時間</div>
        <div class="text-h5">{{ counter }} 秒</div>
      </v-card>
      <v-row class="px-2">
        <v-col
          cols="3"
          v-for="(pid, i) in pids"
          :key="`col-${i}`"
          class="d-flex justify-center align-center"
          :class="
            selected.includes(i)
              ? 'spinda_col__selected'
              : corrected.includes(i)
              ? 'spinda_col__hidden'
              : 'spinda_col'
          "
          @click="select(i)"
        >
          <SpindaBlack
            v-if="
              level === 1 && !corrected.includes(i) && !selected.includes(i)
            "
          />
          <SpindaSvg v-else :pid="pid" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SpindaSvg from "./page/SpindaPairs/SpindaSvg";
import SpindaBlack from "./page/SpindaPairs/SpindaBlack";
import ClearDialog from "./page/SpindaPairs/ClearDialog";

export default {
  metaInfo: {
    title: "パッチール神経衰弱",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "パッチールの神経衰弱 同じ模様のパッチールを探せ！",
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://poketool.mega-yadoran.jp/spinda",
      },
      { vmid: "og:title", property: "og:title", content: "ヤドンの井戸" },
      { vmid: "og:type", property: "og:type", content: "website" },
      {
        vmid: "og:description",
        property: "og:description",
        content: "パッチールの神経衰弱 同じ模様のパッチールを探せ！",
      },
      {
        vmid: "og:site_name",
        property: "og:site_name",
        content: "パッチール神経衰弱 - ヤドンの井戸",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://poketool.mega-yadoran.jp/logo.png",
      },
    ],
  },
  components: { SpindaSvg, SpindaBlack, ClearDialog },
  data: () => ({
    isPlaying: false,
    isCleared: false,
    pids: [],
    selected: [],
    corrected: [],
    number: 12,
    level: 0,
    counter: "0.00",
    startTime: 0,
  }),
  created() {
    this.SpindaBlackPath = "/spinda_black.svg";
  },
  methods: {
    generatePids() {
      const pids = [];
      for (let i = 0; i < this.number / 2; i++) {
        const tmp = this.generatePid();
        pids.push(tmp);
        pids.push(tmp);
      }
      return pids.slice().sort(() => Math.random() - Math.random());
    },
    generatePid() {
      return Math.floor(Math.random() * 0xffffffff);
    },
    start() {
      this.pids = this.generatePids();
      this.isPlaying = true;
      this.isCleared = false;
      this.inPenaltyTime = false;
      this.counter = 0;
      this.selected = [];
      this.corrected = [];
      this.startTime = new Date().getTime();
      this.timer();
    },
    timer() {
      setTimeout(() => {
        if (!this.isPlaying || this.isCleared) return;
        const counter = Math.floor(
          (new Date().getTime() - this.startTime) / 10
        );
        // xxx.xx秒の形に整形
        this.counter =
          Math.floor(counter / 100) + "." + ("00" + (counter % 100)).slice(-2);

        this.timer();
      }, 10);
    },
    select(i) {
      if (this.inPenaltyTime) return;
      // 既に選択済みの場合はselectedから削除
      if (this.selected.includes(i)) {
        this.selected = this.selected.filter((s) => s !== i);
        return;
      }
      // 既に2つ選択済みの場合は何もしない
      if (this.selected.length === 2) return;

      // selectedに追加
      this.selected.push(i);

      // 2つ選択済みの場合は判定
      if (this.selected.length === 2) {
        this.judge();
      }
    },
    judge() {
      const [a, b] = this.selected;
      if (this.pids[a] === this.pids[b]) {
        this.inPenaltyTime = true;
        setTimeout(() => {
          this.corrected.push(a, b);
          this.selected = [];
          this.inPenaltyTime = false;
        }, 500);
        // 全て正解した場合はリセット
        if (this.corrected.length === this.number - 2) {
          this.isCleared = true;
          setTimeout(() => {
            this.inPenaltyTime = false;
            this.isPlaying = false;
          }, 1200);
        }
      } else {
        this.inPenaltyTime = true;
        setTimeout(() => {
          this.inPenaltyTime = false;
          this.selected = [];
        }, 1000);
      }
    },
    restart(level) {
      this.level = level;
      this.start();
    },
  },
};
</script>
<style lang="scss">
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spinda_col {
  &__selected {
    background-color: pink;
  }
  &__hidden {
    visibility: hidden;
  }
}
</style>
