<template>
  <div style="width: 90%" class="mx-auto text-center">
    <v-textarea
      v-model="result"
      ref="resultArea"
      label="変換結果"
      outlined
      hide-details
      width="90%"
      class="my-6 scroll-y"
      @focus="$refs.resultArea.$refs.input.select()"
    />
    <v-btn
      v-if="buttonType === 'copy'"
      color="success"
      class="mx-2"
      @click="copy"
      ref="copyButton"
    >
      <v-tooltip v-model="copySuccessTooltip" top>
        <template v-slot:activator="{ on }">
          <span>コピー</span>
          <span v-on="on" style="display: none"></span>
        </template>
        <span>コピーしました</span>
      </v-tooltip>
    </v-btn>

    <v-btn v-if="buttonType === 'save'" color="success" class="mx-2">
      保存
    </v-btn>
    <ConvertErrorDialog :open.sync="openConvertErrorDialog" />
  </div>
</template>

<script>
import { data } from "../../../../data/present";
import { calcStatus, getNatureRatio } from "../../../../utils/calc";
import ConvertErrorDialog from "./ConvertErrorDialog";

export default {
  components: {
    ConvertErrorDialog,
  },

  props: {
    convertType: {
      type: Number,
      required: true,
    },
    pokemon: {
      type: Array,
      required: true,
    },
    isForArticle: {
      type: Boolean,
      required: true,
    },
    needIndividualValue: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    result: "ここに変換結果が表示されます",
    copySuccessTooltip: false,
    openConvertErrorDialog: false,
    slicedPokemon: [],
  }),

  created() {
    this.pokemonData = data;
  },

  computed: {
    buttonType() {
      switch (this.convertType) {
        case 0:
        case 1:
        case 2:
        case 5:
          return "copy";
        case 3:
        case 4:
          return "save";
        default:
          return "copy";
      }
    },
    saveType() {
      switch (this.convertType) {
        case 0:
          return "html";
        case 1:
          return "md";
        case 2:
          return "txt";
        case 3:
          return "json";
        case 4:
          return "csv";
        case 5:
          return "txt";
        default:
          return "txt";
      }
    },
  },

  methods: {
    clear() {
      this.result = "";
    },
    copy() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.result);
        this.copySuccessTooltip = true;
        setTimeout(() => {
          this.copySuccessTooltip = false;
        }, 1000);
      }
    },
    convert() {
      try {
        this.slicedPokemon = this.pokemon.filter((p) => p.name.value);
        if (this.slicedPokemon.length === 0) {
          this.openConvertErrorDialog = true;
          return false;
        }
        switch (this.convertType) {
          case 0:
            this.convertArticleHTML();
            break;
          case 1:
            this.convertArticleMarkdown();
            break;
          case 2:
            this.convertSimpleText();
            break;
          case 5:
            this.convertArticleHatena();
            break;
          default:
            break;
        }
        return true;
      } catch (e) {
        // console.log(e);
        // this.result = `変換に失敗しました ${e.message}`;
        this.openConvertErrorDialog = true;
        return false;
      }
    },
    getRealValueText(name, ev, iv, nature) {
      const rv = ev.map((e, i) => {
        const p = this.pokemonData.find((po) => po[0] === name);
        const natureRatio = getNatureRatio(nature, i);
        const realValue = Math.floor(
          calcStatus(p[i + 1], e.value, i === 0, iv[i].value) * natureRatio
        );
        if (!e.value || e.value === "") {
          return `${realValue}`;
        } else {
          return `${realValue}(${e.value})`;
        }
      });
      return rv.join("-");
    },
    getMovesText(moves) {
      return moves
        .filter((m) => m.value && m.value !== "")
        .map((m) => m.value)
        .join(" / ");
    },
    formatIv(pokemon) {
      return pokemon.iv.map((item, i) => {
        return i === 1 &&
          !this.needIndividualValue &&
          this.isForArticle &&
          pokemon.isA0
          ? { value: 0 }
          : { value: Number(item.value) };
      });
    },

    convertArticleHTML() {
      const frontPart = `<p>本文</p>
<p>[:contents]</p>
<h3>構築の経緯</h3>
<p>本文</p>
<h3>コンセプト</h3>
<p>本文</p>
<h3>個体紹介</h3>
`;
      const backPart = `<h3>選出</h3>
<p>本文</p>
<h3>重いポケモン</h3>
<p>本文</p>
<h3>最後に</h3>
<p>本文</p>`;
      if (this.isForArticle) {
        this.result =
          frontPart +
          this.slicedPokemon
            .map((p) => {
              const iv = this.formatIv(p);
              return `<h4>${p.name.value} @ ${p.item.value}</h4>
<p>テラスタイプ: ${p.tera.value}<br />
特性: ${p.ability.value}<br />
性格: ${p.nature.value}<br />
${this.getRealValueText(p.name.value, p.ev, iv, p.nature.value)}<br />
${this.getMovesText(p.moves)}</p>
<p>本文</p>
<p></p>
`;
            })
            .join("") +
          backPart;
      } else {
        this.result =
          frontPart +
          this.slicedPokemon
            .map((p) => {
              return `<h4>${p.name.value} @ ${p.item.value}</h4>
<p>テラスタイプ: ${p.tera.value}<br />
特性: ${p.ability.value}<br />
${this.getMovesText(p.moves)}</p>
<p>本文</p>
<p></p>
`;
            })
            .join("") +
          backPart;
      }
    },

    convertArticleMarkdown() {
      const frontPart = `本文

[:contents]

### 構築の経緯
本文

### コンセプト
本文

### 個体紹介`;
      const backPart = `
### 選出
本文

### 重いポケモン
本文

### 最後に
本文
`;
      if (this.isForArticle) {
        this.result =
          frontPart +
          this.slicedPokemon
            .map((p) => {
              const iv = this.formatIv(p);
              return `
#### ${p.name.value} @ ${p.item.value}  
テラスタイプ: ${p.tera.value}  
特性: ${p.ability.value}  
性格: ${p.nature.value}  
${this.getRealValueText(p.name.value, p.ev, iv, p.nature.value)}  
${this.getMovesText(p.moves)}  

本文
`;
            })
            .join("") +
          backPart;
      } else {
        this.result =
          frontPart +
          this.slicedPokemon
            .map((p) => {
              return `
#### ${p.name.value} @ ${p.item.value}  
テラスタイプ: ${p.tera.value}  
特性: ${p.ability.value}  
${this.getMovesText(p.moves)}  

本文
`;
            })
            .join("") +
          backPart;
      }
    },

    convertArticleHatena() {
      const frontPart = `本文

[:contents]

*構築の経緯
本文

*コンセプト
本文

*個体紹介`;
      const backPart = `
*選出
本文

*重いポケモン
本文

*最後に
本文
`;
      if (this.isForArticle) {
        this.result =
          frontPart +
          this.slicedPokemon
            .map((p) => {
              const iv = this.formatIv(p);
              return `
**${p.name.value} @ ${p.item.value}
テラスタイプ: ${p.tera.value}
特性: ${p.ability.value}
性格: ${p.nature.value}
${this.getRealValueText(p.name.value, p.ev, iv, p.nature.value)}
${this.getMovesText(p.moves)}

本文
`;
            })
            .join("") +
          backPart;
      } else {
        this.result =
          frontPart +
          this.slicedPokemon
            .map((p) => {
              return `
**${p.name.value} @ ${p.item.value}
テラスタイプ: ${p.tera.value}
特性: ${p.ability.value}
${this.getMovesText(p.moves)}

本文
`;
            })
            .join("") +
          backPart;
      }
    },
    convertSimpleText() {
      if (this.isForArticle) {
        this.result = this.slicedPokemon
          .map((p) => {
            const iv = this.formatIv(p);
            return `${p.name.value} @ ${p.item.value}
テラスタイプ: ${p.tera.value}
特性: ${p.ability.value}
性格: ${p.nature.value}
${this.getRealValueText(p.name.value, p.ev, iv, p.nature.value)}
${this.getMovesText(p.moves)}

`;
          })
          .join("");
      } else {
        this.result = this.slicedPokemon
          .map((p) => {
            return `${p.name.value} @ ${p.item.value}
テラスタイプ: ${p.tera.value} 
特性: ${p.ability.value}
${this.getMovesText(p.moves)}

`;
          })
          .join("");
      }
    },
  },
};
</script>
