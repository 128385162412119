var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-combobox',{ref:"inputField",staticClass:"my-1 ml",class:_vm.prefix ? 'right-position' : '',attrs:{"items":_vm.items,"filter":_vm.customFilter,"menu-props":_vm.menuProps,"search-input":_vm.searchInput,"append-icon":"","solo":"","flat":"","dense":"","hide-details":"","auto-select-first":"","type":_vm.prefix ? 'number' : 'text',"prefix":_vm.prefix,"label":_vm.placeholder,"placeholder":_vm.placeholder,"background-color":_vm._target.error
      ? 'red lighten-4'
      : _vm._target.focused
      ? 'grey lighten-2'
      : _vm._target.value === '' || _vm._target.value === null
      ? 'grey lighten-4'
      : 'teal lighten-5'},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"focus":(e) => {
      _vm._target.focused = true;
      _vm.eliminateSpace();
      // e.target.select();
    },"blur":(e) => {
      _vm._target.focused = false;
      if (_vm._target.error) {
        _vm.appendSpace();
      }
    }},model:{value:(_vm._target.value),callback:function ($$v) {_vm.$set(_vm._target, "value", $$v)},expression:"_target.value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }