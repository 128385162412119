<template>
  <v-card class="px-2 px-sm-6">
    <v-row v-if="isForArticle">
      <v-col cols="6" align-center class="pr-1 pr-sm-2 pb-0">
        <ComboboxField
          :target.sync="_pokemon.name"
          :items="pokemonNames"
          :items-with-kana="pokemonWithKana"
          placeholder="ポケモン名"
          ref="nameInputField"
        />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="6" align-center class="pr-1 pr-sm-2">
        <ComboboxField
          v-if="!isForArticle"
          :target.sync="_pokemon.name"
          :items="pokemonNames"
          :items-with-kana="pokemonWithKana"
          placeholder="ポケモン名"
          ref="nameInputField"
        />
        <ComboboxField
          v-if="isForArticle"
          :target.sync="_pokemon.nature"
          :items="natureList"
          :items-with-kana="natureWithKana"
          placeholder="性格"
          ref="natureInputField"
        />
        <InputField
          :target.sync="_pokemon.ability"
          placeholder="特性"
          ref="abilityInputField"
        />
        <InputField
          :target.sync="_pokemon.item"
          placeholder="持ち物"
          ref="itemInputField"
        />
        <InputField
          :target.sync="_pokemon.tera"
          placeholder="テラスタイプ"
          ref="teraInputField"
        />
      </v-col>
      <v-col cols="6" class="pl-1 pl-sm-2">
        <InputField
          v-for="j in 4"
          :key="j"
          :target.sync="_pokemon.moves[j - 1]"
          :placeholder="`わざ${j}`"
        />
      </v-col>
    </v-row>

    <v-row v-if="isForArticle" class="mt-0">
      <v-col cols="12" class="text-caption pb-0"
        >努力値（合計:
        <span
          :class="
            totalEV > 510
              ? 'font-weight-bold error--text'
              : totalEV >= 508 && totalEV <= 510
              ? 'font-weight-bold info--text'
              : ''
          "
          >{{ totalEV }}</span
        >）</v-col
      >
      <v-col cols="6" align-center class="pt-0 pr-1 pr-sm-2">
        <InputField
          :target.sync="_pokemon.ev[0]"
          prefix="HP"
          ref="hpEVInputField"
        />
        <InputField
          :target.sync="_pokemon.ev[1]"
          prefix="こうげき"
          ref="attackEVInputField"
        />
        <InputField
          :target.sync="_pokemon.ev[2]"
          prefix="ぼうぎょ"
          ref="defenceEVInputField"
        />
      </v-col>

      <v-col cols="6" class="pt-0 pl-1 pl-sm-2">
        <InputField
          :target.sync="_pokemon.ev[3]"
          prefix="とくこう"
          ref="spAttackEVInputField"
        />
        <InputField
          :target.sync="_pokemon.ev[4]"
          prefix="とくぼう"
          ref="spDefenceEVInputField"
        />
        <InputField
          :target.sync="_pokemon.ev[5]"
          prefix="すばやさ"
          ref="speedEVInputField"
        />
      </v-col>
    </v-row>

    <v-row v-if="isForArticle && needIndividualValue" class="mt-0">
      <v-col cols="12" class="text-caption pb-0">個体値</v-col>
      <v-col cols="6" align-center class="pt-0 pr-1 pr-sm-2">
        <InputField
          :target.sync="_pokemon.iv[0]"
          prefix="HP"
          ref="hpIVInputField"
        />
        <InputField
          :target.sync="_pokemon.iv[1]"
          prefix="こうげき"
          ref="attackIVInputField"
        />
        <InputField
          :target.sync="_pokemon.iv[2]"
          prefix="ぼうぎょ"
          ref="defenceIVInputField"
        />
      </v-col>

      <v-col cols="6" class="pt-0 pl-1 pl-sm-2">
        <InputField
          :target.sync="_pokemon.iv[3]"
          prefix="とくこう"
          ref="spAttackIVInputField"
        />
        <InputField
          :target.sync="_pokemon.iv[4]"
          prefix="とくぼう"
          ref="spDefenceIVInputField"
        />
        <InputField
          :target.sync="_pokemon.iv[5]"
          prefix="すばやさ"
          ref="speedIVInputField"
        />
      </v-col>
    </v-row>

    <v-checkbox
      v-if="isForArticle && !needIndividualValue"
      v-model="_pokemon.isA0"
      label="こうげきの個体値 0"
      :disabled="!isForArticle"
    />
  </v-card>
</template>

<script>
import ComboboxField from "./ComboboxField";
import InputField from "./InputField";
import { pokemonNames, natureList } from "../../../../data/present";
import { convert2katakana } from "../../../../utils/kana";

export default {
  components: {
    ComboboxField,
    InputField,
  },

  props: {
    pokemon: {
      type: Object,
      required: true,
    },
    isForArticle: {
      type: Boolean,
      required: true,
    },
    needIndividualValue: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    focused: false,
  }),

  created() {
    this.pokemonNames = pokemonNames;
    this.pokemonWithKana = pokemonNames.map((name) => {
      return {
        name: name,
        katakana: convert2katakana(name),
      };
    });
    this.natureList = natureList;
    this.natureWithKana = natureList.map((nature) => {
      return {
        name: nature,
        katakana: convert2katakana(nature),
      };
    });
  },

  computed: {
    _pokemon: {
      get() {
        return this.pokemon;
      },
      set(val) {
        this.$emit("update:pokemon", val);
      },
    },
    totalEV() {
      return this.pokemon.ev.reduce(
        (a, b) => a + (b.value ? parseInt(b.value) : 0),
        0
      );
    },
  },

  methods: {
    focus(target) {
      this.$refs[`${target}InputField`].focus();
    },
  },
};
</script>
