var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{ref:"inputField",staticClass:"my-1 ml",class:_vm.prefix ? 'right-position' : '',attrs:{"solo":"","flat":"","dense":"","hide-details":"","type":_vm.prefix ? 'number' : 'text',"prefix":_vm.prefix,"label":_vm.placeholder,"placeholder":_vm.placeholder,"background-color":_vm._target.focused
      ? 'grey lighten-2'
      : _vm._target.value === '' || _vm._target.value === null
      ? 'grey lighten-4'
      : 'teal lighten-5'},on:{"focus":(e) => {
      _vm._target.focused = true;
      // e.target.select();
    },"blur":function($event){_vm._target.focused = false}},model:{value:(_vm._target.value),callback:function ($$v) {_vm.$set(_vm._target, "value", $$v)},expression:"_target.value"}})
}
var staticRenderFns = []

export { render, staticRenderFns }