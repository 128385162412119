<template>
  <v-container class="mb-4">
    <div class="text-h5 text-center">ポケモンSVすばやさ比較</div>

    <v-divider class="my-2 mb-sm-6 dashed" />

    <span class="text-caption grey--text">自分側の入力タイプ</span>
    <v-radio-group v-model="inputType" row class="mt-0">
      <v-radio
        v-for="t in Object.values(inputTypes)"
        :key="t"
        :label="t"
        :value="t"
        :ripple="false"
      />
    </v-radio-group>
    <v-row>
      <v-col sm="6" cols="12" class="py-2">
        <v-card class="pa-4" outlined elevation="1">
          <template v-if="inputType === inputTypes[1]">
            <!-- ポケモン名 -->
            <PokemonInput :pokemon.sync="yours" label="自分側" />

            <v-row class="align-center mt-5">
              <!-- 性格補正 -->
              <div class="ml-3 mr-4 text-caption grey--text">性格補正</div>
              <NatureTypeInput
                :nature-type.sync="natureType"
                :nature-types="natureTypes"
              />

              <div class="ml-4 mr-2 text-caption grey--text">実数値</div>
              <div class="text-h6">{{ realValue }}</div>
            </v-row>

            <!-- 努力値 -->
            <EffortValueInput :effort-value.sync="effortValue" />
          </template>

          <template v-else-if="inputType === inputTypes[3]">
            <v-select
              v-model="savedPokemonIndex"
              :label="
                savedPokemon.length > 0
                  ? '自分側'
                  : 'ポケモンのデータを登録してください'
              "
              :items="savedPokemon"
              :disabled="savedPokemon.length === 0"
              @change="onSelectSavedPokemon"
              placeholder="選んでください"
              no-data-text="登録すると表示されます"
              hide-details
            />

            <v-row class="align-center mt-5 mb-0">
              <!-- 性格補正 -->
              <div class="ml-3 mr-4 text-caption grey--text">性格補正</div>
              <NatureTypeInput
                :nature-type="natureType"
                :nature-types="{ 1: natureType }"
              />

              <div class="ml-4 mr-2 text-caption grey--text">努力値</div>
              <div class="text-h6">{{ effortValue }}</div>

              <div class="ml-6 mr-2 text-caption grey--text">実数値</div>
              <div class="text-h6">{{ realValue }}</div>
            </v-row>
          </template>

          <template v-else>
            <!-- 実数値 -->
            <RealValueInput :real-value.sync="realValueInputed" />
          </template>

          <!-- ランク -->
          <RankValueInput :rank.sync="rank" />

          <div class="d-flex justify-space-between">
            <!-- 状態チェックボックス -->
            <StatesCheckbox :states.sync="yourStates" />

            <!-- 登録 / 削除 ボタン -->
            <v-tooltip
              top
              v-model="showTipMassage"
              :open-on-focus="false"
              :open-on-hover="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="
                    inputType === inputTypes[1] || inputType === inputTypes[3]
                  "
                  v-on="on"
                  v-bind="attrs"
                  :disabled="
                    inputType === inputTypes[3] && savedPokemonIndex === null
                  "
                  :color="inputType === inputTypes[1] ? 'success' : 'error'"
                  @click="inputType === inputTypes[1] ? save() : remove()"
                  small
                  class="mt-4"
                  >{{ inputType === inputTypes[1] ? "登録" : "削除" }}</v-btn
                >
              </template>
              <span
                >{{
                  inputType === inputTypes[1] ? "登録" : "削除"
                }}しました</span
              >
            </v-tooltip>
          </div>
        </v-card>
      </v-col>

      <v-col class="py-2">
        <v-card class="pa-4" outlined elevation="1" height="100%">
          <PokemonInput :pokemon.sync="opponent" label="相手側" />

          <!-- 状態チェックボックス -->
          <StatesCheckbox :states.sync="opponentStates" />
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-8 dashed" />

    <!-- 比較結果 -->
    <div class="mb-4 text-h5 d-flex justify-center align-center">
      <v-icon color="red darken-1">mdi-sword-cross</v-icon>
      <div class="mx-2">比較結果</div>
      <v-icon color="purple lighten-2">mdi-sword-cross</v-icon>
    </div>
    <ResultTable
      :yourSpeed="yourSpeed"
      :opponent="opponent"
      :opponent-states="opponentStates"
    />
  </v-container>
</template>

<script>
import { data } from "../../data/present";
import { getPokemon, insertPokemon, removePokemon } from "../../utils/save";
import { calcSpeed, calcStatus } from "../../utils/calc";
import PokemonInput from "./page/SpeedJudge/PokemonInput";
import NatureTypeInput from "./page/SpeedJudge/NatureTypeInput";
import EffortValueInput from "./page/SpeedJudge/EffortValueInput";
import RealValueInput from "./page/SpeedJudge/RealValueInput";
import RankValueInput from "./page/SpeedJudge/RankValueInput";
import StatesCheckbox from "./page/SpeedJudge/StatesCheckbox";
import ResultTable from "./page/SpeedJudge/ResultTable";

const inputTypes = {
  1: "ポケモン",
  2: "実数値",
  3: "登録済",
};

const natureTypes = {
  1: "mdi-arrow-up-thick",
  2: "mdi-minus",
  3: "mdi-arrow-down-thick",
};

export default {
  components: {
    PokemonInput,
    NatureTypeInput,
    EffortValueInput,
    RealValueInput,
    RankValueInput,
    StatesCheckbox,
    ResultTable,
  },

  data: () => ({
    yours: "カイリュー",
    opponent: "キノガッサ",
    items: [],
    inputType: inputTypes[1],
    realValueInputed: 0,
    natureType: natureTypes[2],
    effortValue: 0,
    rank: 0,
    savedPokemon: [],
    savedPokemonIndex: null,
    yourStates: [
      { label: "スカーフ", value: false },
      { label: "おいかぜ", value: false },
      { label: "まひ", value: false },
    ],
    opponentStates: [
      { label: "おいかぜ", value: false },
      { label: "まひ", value: false },
    ],
    showTipMassage: false,
  }),

  metaInfo: {
    title: "ポケモンSVすばやさ比較",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "ポケモンSVでバトル中に簡単にすばやさを比較できるツールです。手持ちの登録もでき、数秒でサッと比較できます。",
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: "https://poketool.mega-yadoran.jp/speed",
      },
      { vmid: "og:title", property: "og:title", content: "ヤドンの井戸" },
      { vmid: "og:type", property: "og:type", content: "website" },
      {
        vmid: "og:description",
        property: "og:description",
        content:
          "ポケモンSVでバトル中に簡単にすばやさを比較できるツールです。手持ちの登録もでき、数秒でサッと比較できます。",
      },
      {
        vmid: "og:site_name",
        property: "og:site_name",
        content: "ポケモンSVすばやさ比較 - ヤドンの井戸",
      },
      {
        vmid: "og:image",
        property: "og:image",
        content: "https://poketool.mega-yadoran.jp/logo.png",
      },
    ],
  },

  created() {
    this.inputTypes = inputTypes;
    this.natureTypes = natureTypes;
    this.pokemonData = data;

    const savedPokemon = getPokemon();
    if (!savedPokemon) {
      this.savedPokemon = [];
    }
  },
  computed: {
    realValue: {
      get() {
        if (this.inputType === inputTypes[1]) {
          const p = this.pokemonData.find((po) => po[0] === this.yours);
          if (!p) return 0;
          let ratio = 1.0;
          switch (this.natureType) {
            case natureTypes[1]:
              ratio = 1.1;
              break;
            case natureTypes[2]:
              ratio = 1.0;
              break;
            case natureTypes[3]:
              ratio = 0.9;
              break;
          }
          const result = Math.floor(calcStatus(p[6], this.effortValue) * ratio);
          return result;
        } else {
          return this.realValueInputed;
        }
      },
      set() {},
    },

    yourSpeed: {
      get() {
        const scarfRatio = this.yourStates[0].value ? 1.5 : 1.0;
        const tailwindRatio = this.yourStates[1].value ? 2.0 : 1.0;
        const paralysisRatio = this.yourStates[2].value ? 0.5 : 1.0;
        const rankRatio =
          this.rank === 0
            ? 1.0
            : this.rank > 0
            ? (2 + this.rank) / 2
            : 2 / (2 - this.rank);
        return calcSpeed([
          this.realValue,
          rankRatio,
          scarfRatio,
          paralysisRatio,
          tailwindRatio,
        ]);
      },
      set() {},
    },
  },
  methods: {
    formatSavedPokemon(savedPokemon) {
      return savedPokemon.map((p, i) => {
        return {
          text: p.name + " (" + p.realValue + ")" + (p.hasScarf ? " (ス)" : ""),
          value: i,
          realValue: p.realValue,
          natureType: natureTypes[p.natureType],
          effortValue: p.effortValue,
          hasScarf: p.hasScarf,
        };
      });
    },
    onSelectSavedPokemon(index) {
      const p = this.savedPokemon[index];
      this.realValueInputed = p.realValue;
      this.natureType = p.natureType;
      this.effortValue = p.effortValue;
      this.yourStates[0].value = p.hasScarf;
    },
    save() {
      const pokemon = {
        inputType: Object.keys(natureTypes).find(
          (k) => inputTypes[k] === this.inputType
        ),
        hasScarf: this.yourStates[0].value,
      };
      if (this.inputType === inputTypes[1]) {
        pokemon.name = this.yours;
        pokemon.natureType = Object.keys(natureTypes).find(
          (k) => natureTypes[k] === this.natureType
        );
        pokemon.effortValue = this.effortValue;
        pokemon.realValue = this.realValue;
      } else {
        pokemon.realValue = this.realValueInputed;
        pokemon.name = "実数値";
      }
      insertPokemon(pokemon);

      // 登録済みデータを更新
      const savedPokemon = getPokemon();
      this.savedPokemon = this.formatSavedPokemon(savedPokemon);

      setTimeout(() => {
        this.showTipMassage = false;
      }, 1000);
    },

    remove() {
      removePokemon(this.savedPokemonIndex);
      const savedPokemon = getPokemon();
      this.savedPokemon = this.formatSavedPokemon(savedPokemon);
      this.savedPokemonIndex = null;
      this.resetInput();
      setTimeout(() => {
        this.showTipMassage = false;
      }, 1000);
    },

    resetInput() {
      this.realValueInputed = 0;
      this.natureType = natureTypes[2];
      this.effortValue = 0;
      this.realValueInputed = 0;
      this.yourStates = this.yourStates.map((s) => {
        s.value = false;
        return s;
      });
    },
  },
};
</script>
