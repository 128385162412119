<template>
  <v-dialog v-model="_open" width="500">
    <v-card>
      <v-alert type="error"> 変換に失敗しました </v-alert>

      <div class="pt-4 px-4 px-sm-8 text-body-2">
        <p>データが正しく入力されているか確認してください</p>

        <v-divider class="my-4" />
        <p>以下の内容を確認してください。</p>
        <ul>
          <li>ポケモン名が正しく入力されていない場合、エラーになります</li>
          <li>
            <span class="red--text"
              >すがた違いのポケモンは画像の読み取りだけでは正しく変換できません。</span
            >自分で正しいポケモン名を選択してください。
          </li>
        </ul>

        <v-divider class="my-4" />

        <p>
          上記を守ってもうまく変換できない場合は
          <a href="https://www.mega-yadoran.jp/contact" target="_blank"
            >お問い合わせ</a
          >
          からご連絡ください。
        </p>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="_open = false"> OK </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    _open: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
  },
};
</script>

<style scoped>
li {
  margin-bottom: 8px;
}
</style>
