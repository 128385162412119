<template>
  <div class="d-flex flex-wrap">
    <v-checkbox
      v-for="state in _states"
      :key="state.name"
      v-model="state.value"
      :ripple="false"
      hide-details
      class="mr-2"
    >
      <template v-slot:label>
        <span class="text-caption">{{ state.label }}</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  props: {
    states: {
      type: Array,
      required: true,
    },
  },
  computed: {
    _states: {
      get() {
        return this.states;
      },
      set(value) {
        this.$emit("update:states", value);
      },
    },
  },
};
</script>
