<template>
  <v-autocomplete
    v-model="_pokemon"
    :items="items"
    :filter="customFilter"
    item-text="name"
    :label="label"
    placeholder="ポケモン名を入力"
    no-data-text="見つかりません"
    auto-select-first
    menu-props="auto"
    clearable
    hide-details
  />
</template>

<script>
import { pokemonNames } from "../../../../data/present";
import { convert2katakana } from "../../../../utils/kana";

export default {
  props: {
    pokemon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  created() {
    this.pokemonNames = pokemonNames;
    this.items = pokemonNames.map((name) => {
      return {
        name: name,
        katakana: convert2katakana(name),
      };
    });
  },

  computed: {
    _pokemon: {
      get() {
        return this.pokemon;
      },
      set(value) {
        this.$emit("update:pokemon", value);
      },
    },
  },
  methods: {
    customFilter(item, queryText) {
      if (!queryText) return false;

      // 文字列にひらがなやローマ字が含まれる場合、カタカナに変換
      queryText = convert2katakana(queryText);

      // itemにqueryTextが含まれていれば候補に表示
      return item.katakana.indexOf(queryText) > -1;
    },
  },
};
</script>
