<template>
  <v-row class="align-center mt-0">
    <div class="ml-3 mr-5 text-caption grey--text">ランク</div>
    <v-col cols="7" class="pt-1">
      <v-select
        v-model="_rank"
        :items="ranks"
        :menu-props="{ auto: true, transition: false }"
        type="text"
        hide-details
        class="pt-0"
      />
    </v-col>
    <v-btn icon small @click="minus"><v-icon>mdi-minus</v-icon></v-btn>
    <v-btn icon small @click="plus"><v-icon>mdi-plus</v-icon></v-btn>
  </v-row>
</template>

<script>
const ranks = [
  "+6",
  "+5",
  "+4",
  "+3",
  "+2",
  "+1",
  "±0",
  "-1",
  "-2",
  "-3",
  "-4",
  "-5",
  "-6",
];
export default {
  props: {
    rank: {
      type: Number,
      required: true,
    },
  },

  created() {
    this.ranks = ranks.map((text, value) => ({
      text,
      value: (value - 6) * -1,
    }));
  },

  computed: {
    _rank: {
      get() {
        return this.rank;
      },
      set(value) {
        this.$emit("update:rank", value);
      },
    },
  },

  methods: {
    plus() {
      if (this._rank >= 5) {
        this._rank = 6;
      } else if (this._rank < -6) {
        this._rank = -6;
      } else this._rank++;
    },

    minus() {
      if (this._rank <= -5) {
        this._rank = -6;
      } else if (this._rank > 6) {
        this._rank = 6;
      } else this._rank--;
    },
  },
};
</script>
