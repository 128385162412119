import Vue from "vue";
import Router from "vue-router";
import TopPage from "./components/TopPage.vue";
import SpeedJudge from "./components/SpeedJudge.vue";
import Party2Text from "./components/Party2Text.vue";
import SpindaPairs from "./components/SpindaPairs.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    { path: "/", component: TopPage },
    { path: "/speed", component: SpeedJudge },
    { path: "/party", component: Party2Text },
    { path: "/spinda", component: SpindaPairs },
  ],
});
const DEFAULT_TITLE = "ヤドンの井戸";
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});
export default router;
