export const calcStatus = (base, ev, isHP = false, iv = 31) => {
  return (
    Math.floor((base * 2 + iv + Math.floor(ev / 4)) * 0.5) +
    (isHP ? 10 + 50 : 5)
  );
};

export const calcSpeed = (array) => {
  return array.reduce((a, b) => Math.floor(a * b));
};

/**
 * statusIndex: 0 = HP, 1 = Attack, 2 = Defense, 3 = Sp. Atk, 4 = Sp. Def, 5 = Speed
 */
export const getNatureRatio = (nature, statusIndex) => {
  if (statusIndex === 0) return 1;

  switch (nature) {
    case "さみしがり":
      return statusIndex === 1 ? 1.1 : statusIndex === 2 ? 0.9 : 1;
    case "いじっぱり":
      return statusIndex === 1 ? 1.1 : statusIndex === 3 ? 0.9 : 1;
    case "やんちゃ":
      return statusIndex === 1 ? 1.1 : statusIndex === 4 ? 0.9 : 1;
    case "ゆうかん":
      return statusIndex === 1 ? 1.1 : statusIndex === 5 ? 0.9 : 1;
    case "ずぶとい":
      return statusIndex === 2 ? 1.1 : statusIndex === 1 ? 0.9 : 1;
    case "わんぱく":
      return statusIndex === 2 ? 1.1 : statusIndex === 3 ? 0.9 : 1;
    case "のうてんき":
      return statusIndex === 2 ? 1.1 : statusIndex === 4 ? 0.9 : 1;
    case "のんき":
      return statusIndex === 2 ? 1.1 : statusIndex === 5 ? 0.9 : 1;
    case "ひかえめ":
      return statusIndex === 3 ? 1.1 : statusIndex === 1 ? 0.9 : 1;
    case "おっとり":
      return statusIndex === 3 ? 1.1 : statusIndex === 2 ? 0.9 : 1;
    case "うっかりや":
      return statusIndex === 3 ? 1.1 : statusIndex === 4 ? 0.9 : 1;
    case "れいせい":
      return statusIndex === 3 ? 1.1 : statusIndex === 5 ? 0.9 : 1;
    case "おだやか":
      return statusIndex === 4 ? 1.1 : statusIndex === 1 ? 0.9 : 1;
    case "おとなしい":
      return statusIndex === 4 ? 1.1 : statusIndex === 2 ? 0.9 : 1;
    case "しんちょう":
      return statusIndex === 4 ? 1.1 : statusIndex === 3 ? 0.9 : 1;
    case "なまいき":
      return statusIndex === 4 ? 1.1 : statusIndex === 5 ? 0.9 : 1;
    case "おくびょう":
      return statusIndex === 5 ? 1.1 : statusIndex === 1 ? 0.9 : 1;
    case "せっかち":
      return statusIndex === 5 ? 1.1 : statusIndex === 2 ? 0.9 : 1;
    case "ようき":
      return statusIndex === 5 ? 1.1 : statusIndex === 3 ? 0.9 : 1;
    case "むじゃき":
      return statusIndex === 5 ? 1.1 : statusIndex === 4 ? 0.9 : 1;
    default:
      return 1;
  }
};
