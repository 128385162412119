<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="green" dark v-bind="attrs" v-on="on" class="mt-4">
        <v-icon small class="mr-2">mdi-help-circle-outline</v-icon>使える画像
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        読み取りに使える画像
      </v-card-title>

      <v-card-text class="pt-4">
        <p>ポケモンSVの "レンタルチーム" の画像のみ読み取り可能です。</p>
        <div class="text-center">
          <v-img :src="samplePartyImage" />
        </div>

        <v-divider class="my-4" />
        <p>
          Nintendo
          Switchのスクリーンショット画像を加工せずにそのままお使いください。<br />
          次のような場合はうまく読み取れません。
        </p>
        <ul>
          <li>画像の端に空白などのスペースがある</li>
          <li>画像の一部分を切り取っている</li>
          <li>画像を加筆修正している</li>
          <li>ポケモンの数が6匹に満たない</li>
        </ul>

        <v-divider class="my-4" />

        <p>
          上記を守ってもうまく読み取れない場合は
          <a href="https://www.mega-yadoran.jp/contact" target="_blank"
            >お問い合わせ</a
          >
          からご連絡ください。
        </p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import samplePartyImage from "@/assets/sample_party.png";

export default {
  data: () => ({
    dialog: false,
  }),

  created() {
    this.samplePartyImage = samplePartyImage;
  },
};
</script>
