<template>
  <v-dialog v-model="_open" :retain-focus="false" width="600">
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        変換したあとは…
      </v-card-title>

      <v-card-text class="pt-4 black--text">
        <p>
          はじめて「変換」ボタンを押したあなたへ！
          <br />
          「はてなブログ」での利用方法を説明します！
        </p>

        <v-divider class="my-4" />

        <ol>
          <li>
            パーティの情報を入力して「変換」ボタンを押す
            <span class="red--text">←イマココ</span><br />
            (初めての方はHTML形式を選ぶのがおすすめ)
          </li>
          <li>「コピー」ボタンを押して変換結果をコピーする</li>
          <li>
            はてなブログの「<a
              href="https://blog.hatena.ne.jp/my/edit"
              target="_blank"
              >記事を書く</a
            >」ページを開く<br />
            (はてなに登録/ログインしていない場合は先にそれを行う)
          </li>
          <li>
            「HTML編集」タブを押す<br />
            <v-img :src="howToImage1" max-width="300" /><br />
            ※Markdown記法を使う場合は「編集<span class="text-caption">
              見たまま</span
            >」を「Markdown」に変更する
          </li>
          <li>コピーしたテキストを記事の本文部分にペーストする</li>
          <li>
            「プレビュー」タブを押すと、記事のプレビューが表示される<br />
            （本文を修正するとプレビューにも反映される）
          </li>
          <li>
            「編集<span class="text-caption"> 見たまま</span
            >」（または「編集<span class="text-caption"> Markdown</span
            >」）タブに戻す
          </li>
          <li>
            記事のタイトルを入力し、「本文」というサンプルテキストを書き直していく
          </li>
          <li>自由にアレンジして完成！</li>
        </ol>

        <v-divider class="my-4" />

        この説明は、ページ下部の「※はてなブログでの使い方」を押していつでも見ることができます。
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="_open = false"> OK </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import howToImage1 from "@/assets/how_to_1.jpg";

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    _open: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
  },

  created() {
    this.howToImage1 = howToImage1;
  },
};
</script>

<style scoped>
li {
  margin-bottom: 8px;
}
</style>
