<template>
  <div style="max-width: 350px" class="mx-auto">
    <v-row justify="center" align="center">
      <v-col cols="8">
        <v-select
          v-model="_convertType"
          :items="convertTypes"
          outlined
          hide-details
        />
      </v-col>
      <v-col cols="3">
        <v-btn color="primary" @click="convert" ref="convertButton">
          <v-tooltip v-model="_convertSuccessTooltip" top>
            <template v-slot:activator="{ on }">
              <span>変換</span>
              <span v-on="on" style="display: none"></span>
            </template>
            <span>変換しました</span>
          </v-tooltip>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    convertSuccessTooltip: {
      type: Boolean,
      required: false,
    },
    convertType: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    convertTypes: [
      {
        text: "構築記事 (HTML)",
        value: 0,
      },
      {
        text: "構築記事 (はてな記法)",
        value: 5,
      },
      {
        text: "構築記事 (Markdown)",
        value: 1,
      },
      {
        text: "個別解説のみ (テキスト)",
        value: 2,
      },
      // {
      //   text: "JSON",
      //   value: 3,
      // },
      // {
      //   text: "CSV",
      //   value: 4,
      // },
    ],
  }),

  computed: {
    _convertSuccessTooltip: {
      get() {
        return this.convertSuccessTooltip;
      },
      set(value) {
        this.$emit("update:convertSuccessTooltip", value);
      },
    },
    _convertType: {
      get() {
        return this.convertType;
      },
      set(value) {
        this.$emit("update:convertType", value);
      },
    },
  },

  methods: {
    convert() {
      this.$emit("convert");
    },
  },
};
</script>
