const save = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const get = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const updateObject = (objName, key, value) => {
  const obj = get(objName);
  obj[key] = value;
  save(objName, obj);
};

export const initialize = () => {
  if (get("version") === null) {
    save("version", 0);
  }
  if (get("pokemon") === null) {
    save("pokemon", []);
  }
  if (get("party_settings") === null) {
    save("party_settings", {
      isFirst: true,
    });
  }
};

export const getVersion = () => {
  return get("version");
};

export const updateVersion = (version) => {
  save("version", version);
};

export const getPokemon = () => {
  return get("pokemon");
};

export const insertPokemon = (newPokemon) => {
  const pokemon = get("pokemon");
  pokemon.push(newPokemon);
  save("pokemon", pokemon);
};

export const removePokemon = (index) => {
  const pokemon = get("pokemon");
  pokemon.splice(index, 1);
  save("pokemon", pokemon);
};

export const isFirstUseParty2Text = () => {
  return get("party_settings").isFirst;
};

export const saveFirstUsage = () => {
  updateObject("party_settings", "isFirst", false);
};
