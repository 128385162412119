<template>
  <v-text-field
    v-model="_target.value"
    solo
    flat
    dense
    hide-details
    :type="prefix ? 'number' : 'text'"
    :prefix="prefix"
    :label="placeholder"
    :placeholder="placeholder"
    :background-color="
      _target.focused
        ? 'grey lighten-2'
        : _target.value === '' || _target.value === null
        ? 'grey lighten-4'
        : 'teal lighten-5'
    "
    class="my-1 ml"
    :class="prefix ? 'right-position' : ''"
    ref="inputField"
    @focus="
      (e) => {
        _target.focused = true;
        // e.target.select();
      }
    "
    @blur="_target.focused = false"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    target: {
      type: Object,
      default: () => ({
        value: "",
        focused: false,
      }),
    },
  },

  computed: {
    _target: {
      get() {
        return this.target;
      },
      set(value) {
        this.$emit("update:target", value);
      },
    },
  },

  methods: {
    focus() {
      this.$refs.inputField.focus();
    },
  },
};
</script>

<style lang="scss">
.v-text-field__prefix {
  font-size: 12px;
}
.right-position {
  input {
    text-align: right;
  }
}
</style>
