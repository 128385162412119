export const data = [
  ["フシギダネ", 45, 49, 49, 65, 65, 45],
  ["フシギソウ", 60, 62, 63, 80, 80, 60],
  ["フシギバナ", 80, 82, 83, 100, 100, 80],
  ["ヒトカゲ", 39, 52, 43, 60, 50, 65],
  ["リザード", 58, 64, 58, 80, 65, 80],
  ["リザードン", 78, 84, 78, 109, 85, 100],
  ["ゼニガメ", 44, 48, 65, 50, 64, 43],
  ["カメール", 59, 63, 80, 65, 80, 58],
  ["カメックス", 79, 83, 100, 85, 105, 78],
  ["アーボ", 35, 60, 44, 40, 54, 55],
  ["アーボック", 60, 95, 69, 65, 79, 80],
  ["ピカチュウ", 35, 55, 40, 50, 50, 90],
  ["ライチュウ(通常)", 60, 90, 55, 90, 80, 110],
  ["ライチュウ(アローラ)", 60, 85, 50, 95, 85, 110],
  ["サンド(通常)", 50, 75, 85, 20, 30, 40],
  ["サンド(アローラ)", 50, 75, 90, 10, 35, 40],
  ["サンドパン(通常)", 75, 100, 110, 45, 55, 65],
  ["サンドパン(アローラ)", 75, 100, 120, 25, 65, 65],
  ["ピッピ", 70, 45, 48, 60, 65, 35],
  ["ピクシー", 95, 70, 73, 95, 90, 60],
  ["ロコン(通常)", 38, 41, 40, 50, 65, 65],
  ["ロコン(アローラ)", 38, 41, 40, 50, 65, 65],
  ["キュウコン(通常)", 73, 76, 75, 81, 100, 100],
  ["キュウコン(アローラ)", 73, 67, 75, 81, 100, 109],
  ["プリン", 115, 45, 20, 45, 25, 20],
  ["プクリン", 140, 70, 45, 85, 50, 45],
  ["ナゾノクサ", 45, 50, 55, 75, 65, 30],
  ["クサイハナ", 60, 65, 70, 85, 75, 40],
  ["ラフレシア", 75, 80, 85, 110, 90, 50],
  ["コンパン", 60, 55, 50, 40, 55, 45],
  ["モルフォン", 70, 65, 60, 90, 75, 90],
  ["ディグダ(通常)", 10, 55, 25, 35, 45, 95],
  ["ディグダ(アローラ)", 10, 55, 30, 35, 45, 90],
  ["ダグトリオ(通常)", 35, 100, 50, 50, 70, 120],
  ["ダグトリオ(アローラ)", 35, 100, 60, 50, 70, 110],
  ["ニャース(通常)", 40, 45, 35, 40, 40, 90],
  ["ニャース(アローラ)", 40, 35, 35, 50, 40, 90],
  ["ニャース(ガラル)", 50, 65, 55, 40, 40, 40],
  ["ペルシアン(通常)", 65, 70, 60, 65, 65, 115],
  ["ペルシアン(アローラ)", 65, 60, 60, 75, 65, 115],
  ["コダック", 50, 52, 48, 65, 50, 55],
  ["ゴルダック", 80, 82, 78, 95, 80, 85],
  ["マンキー", 40, 80, 35, 35, 45, 70],
  ["オコリザル", 65, 105, 60, 60, 70, 95],
  ["ガーディ(通常)", 55, 70, 45, 70, 50, 60],
  ["ガーディ(ヒスイ)", 60, 75, 45, 65, 50, 55],
  ["ウインディ(通常)", 90, 110, 80, 100, 80, 95],
  ["ウインディ(ヒスイ)", 95, 115, 80, 95, 80, 90],
  ["ニョロモ", 40, 50, 40, 40, 40, 90],
  ["ニョロゾ", 65, 65, 65, 50, 50, 90],
  ["ニョロボン", 90, 95, 95, 70, 90, 70],
  ["マダツボミ", 50, 75, 35, 70, 30, 40],
  ["ウツドン", 65, 90, 50, 85, 45, 55],
  ["ウツボット", 80, 105, 65, 100, 70, 70],
  ["メノクラゲ", 40, 40, 35, 50, 100, 70],
  ["ドククラゲ", 80, 70, 65, 80, 120, 100],
  ["イシツブテ(通常)", 40, 80, 100, 30, 30, 20],
  ["イシツブテ(アローラ)", 40, 80, 100, 30, 30, 20],
  ["ゴローン(通常)", 55, 95, 115, 45, 45, 35],
  ["ゴローン(アローラ)", 55, 95, 115, 45, 45, 35],
  ["ゴローニャ(通常)", 80, 120, 130, 55, 65, 45],
  ["ゴローニャ(アローラ)", 80, 120, 130, 55, 65, 45],
  ["ヤドン(通常)", 90, 65, 65, 40, 40, 15],
  ["ヤドン(ガラル)", 90, 65, 65, 40, 40, 15],
  ["ヤドラン(通常)", 95, 75, 110, 100, 80, 30],
  ["ヤドラン(ガラル)", 95, 100, 95, 100, 70, 30],
  ["コイル", 25, 35, 70, 95, 55, 45],
  ["レアコイル", 50, 60, 95, 120, 70, 70],
  ["ドードー", 35, 85, 45, 35, 35, 75],
  ["ドードリオ", 60, 110, 70, 60, 60, 110],
  ["パウワウ", 65, 45, 55, 45, 70, 45],
  ["ジュゴン", 90, 70, 80, 70, 95, 70],
  ["ベトベター(通常)", 80, 80, 50, 40, 50, 25],
  ["ベトベター(アローラ)", 80, 80, 50, 40, 50, 25],
  ["ベトベトン(通常)", 105, 105, 75, 65, 100, 50],
  ["ベトベトン(アローラ)", 105, 105, 75, 65, 100, 50],
  ["シェルダー", 30, 65, 100, 45, 25, 40],
  ["パルシェン", 50, 95, 180, 85, 45, 70],
  ["ゴース", 30, 35, 30, 100, 35, 80],
  ["ゴースト", 45, 50, 45, 115, 55, 95],
  ["ゲンガー", 60, 65, 60, 130, 75, 110],
  ["スリープ", 60, 48, 45, 43, 90, 42],
  ["スリーパー", 85, 73, 70, 73, 115, 67],
  ["ビリリダマ(通常)", 40, 30, 50, 55, 55, 100],
  ["ビリリダマ(ヒスイ)", 40, 30, 50, 55, 55, 100],
  ["マルマイン(通常)", 60, 50, 70, 80, 80, 150],
  ["マルマイン(ヒスイ)", 60, 50, 70, 80, 80, 150],
  ["タマタマ", 60, 40, 80, 60, 45, 40],
  ["ナッシー(通常)", 95, 95, 85, 125, 75, 55],
  ["ナッシー(アローラ)", 95, 105, 85, 125, 75, 45],
  ["サワムラー", 50, 120, 53, 35, 110, 87],
  ["エビワラー", 50, 105, 79, 35, 110, 76],
  ["ドガース", 40, 65, 95, 60, 45, 35],
  ["マタドガス(通常)", 65, 90, 120, 85, 70, 60],
  ["マタドガス(ガラル)", 65, 90, 120, 85, 70, 60],
  ["サイホーン", 80, 85, 95, 30, 30, 25],
  ["サイドン", 105, 130, 120, 45, 45, 40],
  ["ラッキー", 250, 5, 5, 35, 105, 50],
  ["タッツー", 30, 40, 70, 70, 25, 60],
  ["シードラ", 55, 65, 95, 95, 45, 85],
  ["ストライク", 70, 110, 80, 55, 80, 105],
  ["エレブー", 65, 83, 57, 95, 85, 105],
  ["ブーバー", 65, 95, 57, 100, 85, 93],
  ["ケンタロス(通常)", 75, 100, 95, 40, 70, 110],
  ["ケンタロス(パルデア単)", 75, 110, 105, 30, 70, 100],
  ["ケンタロス(パルデア炎)", 75, 110, 105, 30, 70, 100],
  ["ケンタロス(パルデア水)", 75, 110, 105, 30, 70, 100],
  ["コイキング", 20, 10, 55, 15, 20, 80],
  ["ギャラドス", 95, 125, 79, 60, 100, 81],
  ["ラプラス", 130, 85, 80, 85, 95, 60],
  ["メタモン", 48, 48, 48, 48, 48, 48],
  ["イーブイ", 55, 55, 50, 45, 65, 55],
  ["シャワーズ", 130, 65, 60, 110, 95, 65],
  ["サンダース", 65, 65, 60, 110, 95, 130],
  ["ブースター", 65, 130, 60, 95, 110, 65],
  ["ポリゴン", 65, 60, 70, 85, 75, 40],
  ["カビゴン", 160, 110, 65, 65, 110, 30],
  ["フリーザー(通常)", 90, 85, 100, 95, 125, 85],
  ["フリーザー(ガラル)", 90, 85, 85, 125, 100, 95],
  ["サンダー(通常)", 90, 90, 85, 125, 90, 100],
  ["サンダー(ガラル)", 90, 125, 90, 85, 90, 100],
  ["ファイヤー(通常)", 90, 100, 90, 125, 85, 90],
  ["ファイヤー(ガラル)", 90, 85, 90, 100, 125, 90],
  ["ミニリュウ", 41, 64, 45, 50, 50, 50],
  ["ハクリュー", 61, 84, 65, 70, 70, 70],
  ["カイリュー", 91, 134, 95, 100, 100, 80],
  ["ミュウツー", 106, 110, 90, 154, 90, 130],
  ["ミュウ", 100, 100, 100, 100, 100, 100],
  ["チコリータ", 45, 49, 65, 49, 65, 45],
  ["ベイリーフ", 60, 62, 80, 63, 80, 60],
  ["メガニウム", 80, 82, 100, 83, 100, 80],
  ["ヒノアラシ", 39, 52, 43, 60, 50, 65],
  ["マグマラシ", 58, 64, 58, 80, 65, 80],
  ["バクフーン(通常)", 78, 84, 78, 109, 85, 100],
  ["バクフーン(ヒスイ)", 73, 84, 78, 119, 85, 95],
  ["ワニノコ", 50, 65, 64, 44, 48, 43],
  ["アリゲイツ", 65, 80, 80, 59, 63, 58],
  ["オーダイル", 85, 105, 100, 79, 83, 78],
  ["オタチ", 35, 46, 34, 35, 45, 20],
  ["オオタチ", 85, 76, 64, 45, 55, 90],
  ["ホーホー", 60, 30, 30, 36, 56, 50],
  ["ヨルノズク", 100, 50, 50, 86, 96, 70],
  ["イトマル", 40, 60, 40, 40, 40, 30],
  ["アリアドス", 70, 90, 70, 60, 70, 40],
  ["チョンチー", 75, 38, 38, 56, 56, 67],
  ["ランターン", 125, 58, 58, 76, 76, 67],
  ["ピチュー", 20, 40, 15, 35, 35, 60],
  ["ピィ", 50, 25, 28, 45, 55, 15],
  ["ププリン", 90, 30, 15, 40, 20, 15],
  ["メリープ", 55, 40, 40, 65, 45, 35],
  ["モココ", 70, 55, 55, 80, 60, 45],
  ["デンリュウ", 90, 75, 85, 115, 90, 55],
  ["キレイハナ", 75, 80, 95, 90, 100, 50],
  ["マリル", 70, 20, 50, 20, 50, 40],
  ["マリルリ", 100, 50, 80, 60, 80, 50],
  ["ウソッキー", 70, 100, 115, 30, 65, 30],
  ["ニョロトノ", 90, 75, 75, 90, 100, 70],
  ["ハネッコ", 35, 35, 40, 35, 55, 50],
  ["ポポッコ", 55, 45, 50, 45, 65, 80],
  ["ワタッコ", 75, 55, 70, 55, 95, 110],
  ["エイパム", 55, 70, 55, 40, 55, 85],
  ["ヒマナッツ", 30, 30, 30, 30, 30, 30],
  ["キマワリ", 75, 75, 55, 105, 85, 30],
  ["ヤンヤンマ", 65, 65, 45, 75, 45, 95],
  ["ウパー(通常)", 55, 45, 45, 25, 25, 15],
  ["ウパー(パルデア)", 55, 45, 45, 25, 25, 15],
  ["ヌオー", 95, 85, 85, 65, 65, 35],
  ["エーフィ", 65, 65, 60, 130, 95, 110],
  ["ブラッキー", 95, 65, 110, 60, 130, 65],
  ["ヤミカラス", 60, 85, 42, 85, 42, 91],
  ["ヤドキング(通常)", 95, 75, 80, 100, 110, 30],
  ["ヤドキング(ガラル)", 95, 65, 80, 110, 110, 30],
  ["ムウマ", 60, 60, 60, 85, 85, 85],
  ["キリンリキ", 70, 80, 65, 90, 65, 85],
  ["クヌギダマ", 50, 65, 90, 35, 35, 15],
  ["フォレトス", 75, 90, 140, 60, 60, 40],
  ["ノコッチ", 100, 70, 70, 65, 65, 45],
  ["グライガー", 65, 75, 105, 35, 65, 85],
  ["ブルー", 60, 80, 50, 40, 40, 30],
  ["グランブル", 90, 120, 75, 60, 60, 45],
  ["ハリーセン(通常)", 65, 95, 85, 55, 55, 85],
  ["ハリーセン(ヒスイ)", 65, 95, 85, 55, 55, 85],
  ["ハッサム", 70, 130, 100, 55, 80, 65],
  ["ヘラクロス", 80, 125, 75, 40, 95, 85],
  ["ニューラ(通常)", 55, 95, 55, 35, 75, 115],
  ["ニューラ(ヒスイ)", 55, 95, 55, 35, 75, 115],
  ["ヒメグマ", 60, 80, 50, 50, 50, 40],
  ["リングマ", 90, 130, 75, 75, 75, 55],
  ["マグマッグ", 40, 40, 40, 70, 40, 20],
  ["マグカルゴ", 60, 50, 120, 90, 80, 30],
  ["ウリムー", 50, 50, 40, 30, 30, 50],
  ["イノムー", 100, 100, 80, 60, 60, 50],
  ["デリバード", 45, 55, 45, 65, 45, 75],
  ["エアームド", 65, 80, 140, 40, 70, 70],
  ["デルビル", 45, 60, 30, 80, 50, 65],
  ["ヘルガー", 75, 90, 50, 110, 80, 95],
  ["キングドラ", 75, 95, 95, 95, 95, 85],
  ["ゴマゾウ", 90, 60, 60, 40, 40, 40],
  ["ドンファン", 90, 120, 120, 60, 60, 50],
  ["ポリゴン２", 85, 80, 90, 105, 95, 60],
  ["オドシシ", 73, 95, 62, 85, 65, 85],
  ["ドーブル", 55, 20, 35, 20, 45, 75],
  ["バルキー", 35, 35, 35, 35, 35, 35],
  ["カポエラー", 50, 95, 95, 35, 110, 70],
  ["エレキッド", 45, 63, 37, 65, 55, 95],
  ["ブビィ", 45, 75, 37, 70, 55, 83],
  ["ハピナス", 255, 10, 10, 75, 135, 55],
  ["ライコウ", 90, 85, 75, 115, 100, 115],
  ["エンテイ", 115, 115, 85, 90, 75, 100],
  ["スイクン", 100, 75, 115, 90, 115, 85],
  ["ヨーギラス", 50, 64, 50, 45, 50, 41],
  ["サナギラス", 70, 84, 70, 65, 70, 51],
  ["バンギラス", 100, 134, 110, 95, 100, 61],
  ["ルギア", 106, 90, 130, 90, 154, 110],
  ["ホウオウ", 106, 130, 90, 110, 154, 90],
  ["キモリ", 40, 45, 35, 65, 55, 70],
  ["ジュプトル", 50, 65, 45, 85, 65, 95],
  ["ジュカイン", 70, 85, 65, 105, 85, 120],
  ["アチャモ", 45, 60, 40, 70, 50, 45],
  ["ワカシャモ", 60, 85, 60, 85, 60, 55],
  ["バシャーモ", 80, 120, 70, 110, 70, 80],
  ["ミズゴロウ", 50, 70, 50, 50, 50, 40],
  ["ヌマクロー", 70, 85, 70, 60, 70, 50],
  ["ラグラージ", 100, 110, 90, 85, 90, 60],
  ["ポチエナ", 35, 55, 35, 30, 30, 35],
  ["グラエナ", 70, 90, 70, 60, 60, 70],
  ["ハスボー", 40, 30, 30, 40, 50, 30],
  ["ハスブレロ", 60, 50, 50, 60, 70, 50],
  ["ルンパッパ", 80, 70, 70, 90, 100, 70],
  ["タネボー", 40, 40, 50, 30, 30, 30],
  ["コノハナ", 70, 70, 40, 60, 40, 60],
  ["ダーテング", 90, 100, 60, 90, 60, 80],
  ["キャモメ", 40, 30, 30, 55, 30, 85],
  ["ペリッパー", 60, 50, 100, 95, 70, 65],
  ["ラルトス", 28, 25, 25, 45, 35, 40],
  ["キルリア", 38, 35, 35, 65, 55, 50],
  ["サーナイト", 68, 65, 65, 125, 115, 80],
  ["アメタマ", 40, 30, 32, 50, 52, 65],
  ["アメモース", 70, 60, 62, 100, 82, 80],
  ["キノココ", 60, 40, 60, 40, 60, 35],
  ["キノガッサ", 60, 130, 80, 60, 60, 70],
  ["ナマケロ", 60, 60, 60, 35, 35, 30],
  ["ヤルキモノ", 80, 80, 80, 55, 55, 90],
  ["ケッキング", 150, 160, 100, 95, 65, 100],
  ["マクノシタ", 72, 60, 30, 20, 30, 25],
  ["ハリテヤマ", 144, 120, 60, 40, 60, 50],
  ["ルリリ", 50, 20, 40, 20, 40, 20],
  ["ノズパス", 30, 45, 135, 45, 90, 30],
  ["ヤミラミ", 50, 75, 75, 65, 65, 50],
  ["アサナン", 30, 40, 55, 40, 55, 60],
  ["チャーレム", 60, 60, 75, 60, 75, 80],
  ["プラスル", 60, 50, 40, 85, 75, 95],
  ["マイナン", 60, 40, 50, 75, 85, 95],
  ["バルビート", 65, 73, 75, 47, 85, 85],
  ["イルミーゼ", 65, 47, 75, 73, 85, 85],
  ["ゴクリン", 70, 43, 53, 43, 53, 40],
  ["マルノーム", 100, 73, 83, 73, 83, 55],
  ["ドンメル", 60, 60, 40, 65, 45, 35],
  ["バクーダ", 70, 100, 70, 105, 75, 40],
  ["コータス", 70, 85, 140, 85, 70, 20],
  ["バネブー", 60, 25, 35, 70, 80, 60],
  ["ブーピッグ", 80, 45, 65, 90, 110, 80],
  ["ナックラー", 45, 100, 45, 45, 45, 10],
  ["ビブラーバ", 50, 70, 50, 50, 50, 70],
  ["フライゴン", 80, 100, 80, 80, 80, 100],
  ["サボネア", 50, 85, 40, 85, 40, 35],
  ["ノクタス", 70, 115, 60, 115, 60, 55],
  ["チルット", 45, 40, 60, 40, 75, 50],
  ["チルタリス", 75, 70, 90, 70, 105, 80],
  ["ザングース", 73, 115, 60, 60, 60, 90],
  ["ハブネーク", 73, 100, 60, 100, 60, 65],
  ["ドジョッチ", 50, 48, 43, 46, 41, 60],
  ["ナマズン", 110, 78, 73, 76, 71, 60],
  ["ヘイガニ", 43, 80, 65, 50, 35, 35],
  ["シザリガー", 63, 120, 85, 90, 55, 55],
  ["ヒンバス", 20, 15, 20, 10, 55, 80],
  ["ミロカロス", 95, 60, 79, 100, 125, 81],
  ["カゲボウズ", 44, 75, 35, 63, 33, 45],
  ["ジュペッタ", 64, 115, 65, 83, 63, 65],
  ["ヨマワル", 20, 40, 90, 30, 90, 25],
  ["サマヨール", 40, 70, 130, 60, 130, 25],
  ["トロピウス", 99, 68, 83, 72, 87, 51],
  ["チリーン", 75, 50, 80, 95, 90, 65],
  ["ユキワラシ", 50, 50, 50, 50, 50, 50],
  ["オニゴーリ", 80, 80, 80, 80, 80, 80],
  ["ラブカス", 43, 30, 55, 40, 65, 97],
  ["タツベイ", 45, 75, 60, 40, 30, 50],
  ["コモルー", 65, 95, 100, 60, 50, 50],
  ["ボーマンダ", 95, 135, 80, 110, 80, 100],
  ["ダンバル", 40, 55, 80, 35, 60, 30],
  ["メタング", 60, 75, 100, 55, 80, 50],
  ["メタグロス", 80, 135, 130, 95, 90, 70],
  ["レジロック", 80, 100, 200, 50, 100, 50],
  ["レジアイス", 80, 50, 100, 100, 200, 50],
  ["レジスチル", 80, 75, 150, 75, 150, 50],
  ["ラティアス", 80, 80, 90, 110, 130, 110],
  ["ラティオス", 80, 90, 80, 130, 110, 110],
  ["カイオーガ", 100, 100, 90, 150, 140, 90],
  ["グラードン", 100, 150, 140, 100, 90, 90],
  ["レックウザ", 105, 150, 90, 150, 90, 95],
  ["ジラーチ", 100, 100, 100, 100, 100, 100],
  ["デオキシス(ノーマル)", 50, 150, 50, 150, 50, 150],
  ["デオキシス(アタック)", 50, 180, 20, 180, 20, 150],
  ["デオキシス(ディフェンス)", 50, 70, 160, 70, 160, 90],
  ["デオキシス(スピード)", 50, 95, 90, 95, 90, 180],
  ["ナエトル", 55, 68, 64, 45, 55, 31],
  ["ハヤシガメ", 75, 89, 85, 55, 65, 36],
  ["ドダイトス", 95, 109, 105, 75, 85, 56],
  ["ヒコザル", 44, 58, 44, 58, 44, 61],
  ["モウカザル", 64, 78, 52, 78, 52, 81],
  ["ゴウカザル", 76, 104, 71, 104, 71, 108],
  ["ポッチャマ", 53, 51, 53, 61, 56, 40],
  ["ポッタイシ", 64, 66, 68, 81, 76, 50],
  ["エンペルト", 84, 86, 88, 111, 101, 60],
  ["ムックル", 40, 55, 30, 30, 30, 60],
  ["ムクバード", 55, 75, 50, 40, 40, 80],
  ["ムクホーク", 85, 120, 70, 50, 60, 100],
  ["コロボーシ", 37, 25, 41, 25, 41, 25],
  ["コロトック", 77, 85, 51, 55, 51, 65],
  ["コリンク", 45, 65, 34, 40, 34, 45],
  ["ルクシオ", 60, 85, 49, 60, 49, 60],
  ["レントラー", 80, 120, 79, 95, 79, 70],
  ["ズガイドス", 67, 125, 40, 30, 30, 58],
  ["ラムパルド", 97, 165, 60, 65, 50, 58],
  ["タテトプス", 30, 42, 118, 42, 88, 30],
  ["トリデプス", 60, 52, 168, 47, 138, 30],
  ["ミツハニー", 30, 30, 42, 30, 42, 70],
  ["ビークイン", 70, 80, 102, 80, 102, 40],
  ["パチリス", 60, 45, 70, 45, 90, 95],
  ["ブイゼル", 55, 65, 35, 60, 30, 85],
  ["フローゼル", 85, 105, 55, 85, 50, 115],
  ["カラナクシ", 76, 48, 48, 57, 62, 34],
  ["トリトドン", 111, 83, 68, 92, 82, 39],
  ["エテボース", 75, 100, 66, 60, 66, 115],
  ["フワンテ", 90, 50, 34, 60, 44, 70],
  ["フワライド", 150, 80, 44, 90, 54, 80],
  ["ムウマージ", 60, 60, 60, 105, 105, 105],
  ["ドンカラス", 100, 125, 52, 105, 52, 71],
  ["リーシャン", 45, 30, 50, 65, 50, 45],
  ["スカンプー", 63, 63, 47, 41, 41, 74],
  ["スカタンク", 103, 93, 67, 71, 61, 84],
  ["ドーミラー", 57, 24, 86, 24, 86, 23],
  ["ドータクン", 67, 89, 116, 79, 116, 33],
  ["ウソハチ", 50, 80, 95, 10, 45, 10],
  ["ピンプク", 100, 5, 5, 15, 65, 30],
  ["ミカルゲ", 50, 92, 108, 92, 108, 35],
  ["フカマル", 58, 70, 45, 40, 45, 42],
  ["ガバイト", 68, 90, 65, 50, 55, 82],
  ["ガブリアス", 108, 130, 95, 80, 85, 102],
  ["ゴンベ", 135, 85, 40, 40, 85, 5],
  ["リオル", 40, 70, 40, 35, 40, 60],
  ["ルカリオ", 70, 110, 70, 115, 70, 90],
  ["ヒポポタス", 68, 72, 78, 38, 42, 32],
  ["カバルドン", 108, 112, 118, 68, 72, 47],
  ["グレッグル", 48, 61, 40, 61, 40, 50],
  ["ドクロッグ", 83, 106, 65, 86, 65, 85],
  ["ケイコウオ", 49, 49, 56, 49, 61, 66],
  ["ネオラント", 69, 69, 76, 69, 86, 91],
  ["ユキカブリ", 60, 62, 50, 62, 60, 40],
  ["ユキノオー", 90, 92, 75, 92, 85, 60],
  ["マニューラ", 70, 120, 65, 45, 85, 125],
  ["ジバコイル", 70, 70, 115, 130, 90, 60],
  ["ドサイドン", 115, 140, 130, 55, 55, 40],
  ["エレキブル", 75, 123, 67, 95, 85, 95],
  ["ブーバーン", 75, 95, 67, 125, 95, 83],
  ["メガヤンマ", 86, 76, 86, 116, 56, 95],
  ["リーフィア", 65, 110, 130, 60, 65, 95],
  ["グレイシア", 65, 60, 110, 130, 95, 65],
  ["グライオン", 75, 95, 125, 45, 75, 95],
  ["マンムー", 110, 130, 80, 70, 60, 80],
  ["ポリゴンＺ", 85, 80, 70, 135, 75, 90],
  ["エルレイド", 68, 125, 65, 65, 115, 80],
  ["ダイノーズ", 60, 55, 145, 75, 150, 40],
  ["ヨノワール", 45, 100, 135, 65, 135, 45],
  ["ユキメノコ", 70, 80, 70, 80, 70, 110],
  ["ロトム(通常)", 50, 50, 77, 95, 77, 91],
  ["ロトム(ヒート)", 50, 65, 107, 105, 107, 86],
  ["ロトム(ウォッシュ)", 50, 65, 107, 105, 107, 86],
  ["ロトム(フロスト)", 50, 65, 107, 105, 107, 86],
  ["ロトム(スピン)", 50, 65, 107, 105, 107, 86],
  ["ロトム(カット)", 50, 65, 107, 105, 107, 86],
  ["ユクシー", 75, 75, 130, 75, 130, 95],
  ["エムリット", 80, 105, 105, 105, 105, 80],
  ["アグノム", 75, 125, 70, 125, 70, 115],
  ["ディアルガ(通常)", 100, 120, 120, 150, 100, 90],
  ["ディアルガ(オリジン)", 100, 100, 120, 150, 120, 90],
  ["パルキア(通常)", 90, 120, 100, 150, 120, 100],
  ["パルキア(オリジン)", 90, 100, 100, 150, 120, 120],
  ["ヒードラン", 91, 90, 106, 130, 106, 77],
  ["レジギガス", 110, 160, 110, 80, 110, 100],
  ["ギラティナ(アナザー)", 150, 100, 120, 100, 120, 90],
  ["ギラティナ(オリジン)", 150, 120, 100, 120, 100, 90],
  ["クレセリア", 120, 70, 110, 75, 120, 85],
  ["フィオネ", 80, 80, 80, 80, 80, 80],
  ["マナフィ", 100, 100, 100, 100, 100, 100],
  ["ダークライ", 70, 90, 90, 135, 90, 125],
  ["シェイミ(ランド)", 100, 100, 100, 100, 100, 100],
  ["シェイミ(スカイ)", 100, 103, 75, 120, 75, 127],
  ["アルセウス", 120, 120, 120, 120, 120, 120],
  ["ツタージャ", 45, 45, 55, 45, 55, 63],
  ["ジャノビー", 60, 60, 75, 60, 75, 83],
  ["ジャローダ", 75, 75, 95, 75, 95, 113],
  ["ポカブ", 65, 63, 45, 45, 45, 45],
  ["チャオブー", 90, 93, 55, 70, 55, 55],
  ["エンブオー", 110, 123, 65, 100, 65, 65],
  ["ミジュマル", 55, 55, 45, 63, 45, 45],
  ["フタチマル", 75, 75, 60, 83, 60, 60],
  ["ダイケンキ(通常)", 95, 100, 85, 108, 70, 70],
  ["ダイケンキ(ヒスイ)", 90, 108, 80, 100, 65, 85],
  ["シママ", 45, 60, 32, 50, 32, 76],
  ["ゼブライカ", 75, 100, 63, 80, 63, 116],
  ["モグリュー", 60, 85, 40, 30, 45, 68],
  ["ドリュウズ", 110, 135, 60, 50, 65, 88],
  ["ドッコラー", 75, 80, 55, 25, 35, 35],
  ["ドテッコツ", 85, 105, 85, 40, 50, 40],
  ["ローブシン", 105, 140, 95, 55, 65, 45],
  ["クルミル", 45, 53, 70, 40, 60, 42],
  ["クルマユ", 55, 63, 90, 50, 80, 42],
  ["ハハコモリ", 75, 103, 80, 70, 80, 92],
  ["モンメン", 40, 27, 60, 37, 50, 66],
  ["エルフーン", 60, 67, 85, 77, 75, 116],
  ["チュリネ", 45, 35, 50, 70, 50, 30],
  ["ドレディア(通常)", 70, 60, 75, 110, 75, 90],
  ["ドレディア(ヒスイ)", 70, 105, 75, 50, 75, 105],
  ["バスラオ", 70, 92, 65, 80, 55, 98],
  ["メグロコ", 50, 72, 35, 35, 35, 65],
  ["ワルビル", 60, 82, 45, 45, 45, 74],
  ["ワルビアル", 95, 117, 80, 65, 70, 92],
  ["ズルッグ", 50, 75, 70, 35, 70, 48],
  ["ズルズキン", 65, 90, 115, 45, 115, 58],
  ["ゾロア(通常)", 40, 65, 40, 80, 40, 65],
  ["ゾロア(ヒスイ)", 35, 60, 40, 85, 40, 70],
  ["ゾロアーク(通常)", 60, 105, 60, 120, 60, 105],
  ["ゾロアーク(ヒスイ)", 55, 100, 60, 125, 60, 110],
  ["チラーミィ", 55, 50, 40, 40, 40, 75],
  ["チラチーノ", 75, 95, 60, 65, 60, 115],
  ["ゴチム", 45, 30, 50, 55, 65, 45],
  ["ゴチミル", 60, 45, 70, 75, 85, 55],
  ["ゴチルゼル", 70, 55, 95, 95, 110, 65],
  ["ユニラン", 45, 30, 40, 105, 50, 20],
  ["ダブラン", 65, 40, 50, 125, 60, 30],
  ["ランクルス", 110, 65, 75, 125, 85, 30],
  ["コアルヒー", 62, 44, 50, 44, 50, 55],
  ["スワンナ", 75, 87, 63, 87, 63, 98],
  ["シキジカ", 60, 60, 50, 40, 50, 75],
  ["メブキジカ", 80, 100, 70, 60, 70, 95],
  ["タマゲタケ", 69, 55, 45, 55, 55, 15],
  ["モロバレル", 114, 85, 70, 85, 80, 30],
  ["ママンボウ", 165, 75, 80, 40, 45, 65],
  ["バチュル", 50, 47, 50, 57, 50, 65],
  ["デンチュラ", 70, 77, 60, 97, 60, 108],
  ["シビシラス", 35, 55, 40, 45, 40, 60],
  ["シビビール", 65, 85, 70, 75, 70, 40],
  ["シビルドン", 85, 115, 80, 105, 80, 50],
  ["ヒトモシ", 50, 30, 55, 65, 55, 20],
  ["ランプラー", 60, 40, 60, 95, 60, 55],
  ["シャンデラ", 60, 55, 90, 145, 90, 80],
  ["キバゴ", 46, 87, 60, 30, 40, 57],
  ["オノンド", 66, 117, 70, 40, 50, 67],
  ["オノノクス", 76, 147, 90, 60, 70, 97],
  ["クマシュン", 55, 70, 40, 60, 40, 40],
  ["ツンベアー", 95, 130, 80, 70, 80, 50],
  ["フリージオ", 80, 50, 50, 95, 135, 105],
  ["コジョフー", 45, 85, 50, 55, 50, 65],
  ["コジョンド", 65, 125, 60, 95, 60, 105],
  ["ゴビット", 59, 74, 50, 35, 50, 35],
  ["ゴルーグ", 89, 124, 80, 55, 80, 55],
  ["コマタナ", 45, 85, 70, 40, 40, 60],
  ["キリキザン", 65, 125, 100, 60, 70, 70],
  ["ワシボン", 70, 83, 50, 37, 50, 60],
  ["ウォーグル(通常)", 100, 123, 75, 57, 75, 80],
  ["ウォーグル(ヒスイ)", 110, 83, 70, 112, 70, 65],
  ["バルチャイ", 70, 55, 75, 45, 65, 60],
  ["バルジーナ", 110, 65, 105, 55, 95, 80],
  ["モノズ", 52, 65, 50, 45, 50, 38],
  ["ジヘッド", 72, 85, 70, 65, 70, 58],
  ["サザンドラ", 92, 105, 90, 125, 90, 98],
  ["メラルバ", 55, 85, 55, 50, 55, 60],
  ["ウルガモス", 85, 60, 65, 135, 105, 100],
  ["コバルオン", 91, 90, 129, 90, 72, 108],
  ["テラキオン", 91, 129, 90, 72, 90, 108],
  ["ビリジオン", 91, 90, 72, 90, 129, 108],
  ["トルネロス(けしん)", 79, 115, 70, 125, 80, 111],
  ["トルネロス(れいじゅう)", 79, 100, 80, 110, 90, 121],
  ["ボルトロス(けしん)", 79, 115, 70, 125, 80, 111],
  ["ボルトロス(れいじゅう)", 79, 105, 70, 145, 80, 101],
  ["レシラム", 100, 120, 100, 150, 120, 90],
  ["ゼクロム", 100, 150, 120, 120, 100, 90],
  ["ランドロス(けしん)", 89, 125, 90, 115, 80, 101],
  ["ランドロス(れいじゅう)", 89, 145, 90, 105, 80, 91],
  ["キュレム(通常)", 125, 130, 90, 130, 90, 95],
  ["キュレム(ホワイト)", 125, 120, 90, 170, 100, 95],
  ["キュレム(ブラック)", 125, 170, 100, 120, 90, 95],
  ["ケルディオ(いつも)", 91, 72, 90, 129, 90, 108],
  ["ケルディオ(かくご)", 91, 72, 90, 129, 90, 108],
  ["メロエッタ(ボイス)", 100, 77, 77, 128, 128, 90],
  ["メロエッタ(ステップ)", 100, 128, 90, 77, 77, 128],
  ["ハリマロン", 56, 61, 65, 48, 45, 38],
  ["ハリボーグ", 61, 78, 95, 56, 58, 57],
  ["ブリガロン", 88, 107, 122, 74, 75, 64],
  ["フォッコ", 40, 45, 40, 62, 60, 60],
  ["テールナー", 59, 59, 58, 90, 70, 73],
  ["マフォクシー", 75, 69, 72, 114, 100, 104],
  ["ケロマツ", 41, 56, 40, 62, 44, 71],
  ["ゲコガシラ", 54, 63, 52, 83, 56, 97],
  ["ゲッコウガ", 72, 95, 67, 103, 71, 122],
  ["ヤヤコマ", 45, 50, 43, 40, 38, 62],
  ["ヒノヤコマ", 62, 73, 55, 56, 52, 84],
  ["ファイアロー", 78, 81, 71, 74, 69, 126],
  ["コフキムシ", 38, 35, 40, 27, 25, 35],
  ["コフーライ", 45, 22, 60, 27, 30, 29],
  ["ビビヨン", 80, 52, 50, 90, 50, 89],
  ["シシコ", 62, 50, 58, 73, 54, 72],
  ["カエンジシ", 86, 68, 72, 109, 66, 106],
  ["フラベベ", 44, 38, 39, 61, 79, 42],
  ["フラエッテ", 54, 45, 47, 75, 98, 52],
  ["フラージェス", 78, 65, 68, 112, 154, 75],
  ["メェークル", 66, 65, 48, 62, 57, 52],
  ["ゴーゴート", 123, 100, 62, 97, 81, 68],
  ["ニャスパー", 62, 48, 54, 63, 60, 68],
  ["ニャオニクス(オス)", 74, 48, 76, 83, 81, 104],
  ["ニャオニクス(メス)", 74, 48, 76, 83, 81, 104],
  ["マーイーカ", 53, 54, 53, 37, 46, 45],
  ["カラマネロ", 86, 92, 88, 68, 75, 73],
  ["クズモー", 50, 60, 60, 60, 60, 30],
  ["ドラミドロ", 65, 75, 90, 97, 123, 44],
  ["ウデッポウ", 50, 53, 62, 58, 63, 44],
  ["ブロスター", 71, 73, 88, 120, 89, 59],
  ["ニンフィア", 95, 65, 65, 110, 130, 60],
  ["ルチャブル", 78, 92, 75, 74, 63, 118],
  ["デデンネ", 67, 58, 57, 81, 67, 101],
  ["メレシー", 50, 50, 150, 50, 150, 50],
  ["ヌメラ", 45, 50, 35, 55, 75, 40],
  ["ヌメイル(通常)", 68, 75, 53, 83, 113, 60],
  ["ヌメイル(ヒスイ)", 58, 75, 83, 83, 113, 40],
  ["ヌメルゴン(通常)", 90, 100, 70, 110, 150, 80],
  ["ヌメルゴン(ヒスイ)", 80, 100, 100, 110, 150, 60],
  ["クレッフィ", 57, 80, 91, 80, 87, 75],
  ["ボクレー", 43, 70, 48, 50, 60, 38],
  ["オーロット", 85, 110, 76, 65, 82, 56],
  ["カチコール", 55, 69, 85, 32, 35, 28],
  ["クレベース(通常)", 95, 117, 184, 44, 46, 28],
  ["クレベース(ヒスイ)", 95, 127, 184, 34, 36, 38],
  ["オンバット", 40, 30, 35, 45, 40, 55],
  ["オンバーン", 85, 70, 80, 97, 80, 123],
  ["ディアンシー", 50, 100, 150, 100, 150, 50],
  ["フーパ(いましめられし)", 80, 110, 60, 150, 130, 70],
  ["フーパ(ときはなたれし)", 80, 160, 60, 170, 130, 80],
  ["ボルケニオン", 80, 110, 120, 130, 90, 70],
  ["モクロー", 68, 55, 55, 50, 50, 42],
  ["フクスロー", 78, 75, 75, 70, 70, 52],
  ["ジュナイパー(通常)", 78, 107, 75, 100, 100, 70],
  ["ジュナイパー(ヒスイ)", 88, 112, 80, 95, 95, 60],
  ["ニャビー", 45, 65, 40, 60, 40, 70],
  ["ニャヒート", 65, 85, 50, 80, 50, 90],
  ["ガオガエン", 95, 115, 90, 80, 90, 60],
  ["アシマリ", 50, 54, 54, 66, 56, 40],
  ["オシャマリ", 60, 69, 69, 91, 81, 50],
  ["アシレーヌ", 80, 74, 74, 126, 116, 60],
  ["ツツケラ", 35, 75, 30, 30, 30, 65],
  ["ケララッパ", 55, 85, 50, 40, 50, 75],
  ["ドデカバシ", 80, 120, 75, 75, 75, 60],
  ["ヤングース", 48, 70, 30, 30, 30, 45],
  ["デカグース", 88, 110, 60, 55, 60, 45],
  ["アゴジムシ", 47, 62, 45, 55, 45, 46],
  ["デンヂムシ", 57, 82, 95, 55, 75, 36],
  ["クワガノン", 77, 70, 90, 145, 75, 43],
  ["マケンカニ", 47, 82, 57, 42, 47, 63],
  ["ケケンカニ", 97, 132, 77, 62, 67, 43],
  ["オドリドリ(めらめら)", 75, 70, 70, 98, 70, 93],
  ["オドリドリ(ぱちぱち)", 75, 70, 70, 98, 70, 93],
  ["オドリドリ(ふらふら)", 75, 70, 70, 98, 70, 93],
  ["オドリドリ(まいまい)", 75, 70, 70, 98, 70, 93],
  ["アブリー", 40, 45, 40, 55, 40, 84],
  ["アブリボン", 60, 55, 60, 95, 70, 124],
  ["イワンコ", 45, 65, 40, 30, 40, 60],
  ["ルガルガン(まひる)", 75, 115, 65, 55, 65, 112],
  ["ルガルガン(まよなか)", 85, 115, 75, 55, 75, 82],
  ["ルガルガン(たそがれ)", 75, 117, 65, 55, 65, 110],
  ["ヒドイデ", 50, 53, 62, 43, 52, 45],
  ["ドヒドイデ", 50, 63, 152, 53, 142, 35],
  ["ドロバンコ", 70, 100, 70, 45, 55, 45],
  ["バンバドロ", 100, 125, 100, 55, 85, 35],
  ["シズクモ", 38, 40, 52, 40, 72, 27],
  ["オニシズクモ", 68, 70, 92, 50, 132, 42],
  ["カリキリ", 40, 55, 35, 50, 35, 35],
  ["ラランテス", 70, 105, 90, 80, 90, 45],
  ["ヤトウモリ", 48, 44, 40, 71, 40, 77],
  ["エンニュート", 68, 64, 60, 111, 60, 117],
  ["アマカジ", 42, 30, 38, 30, 38, 32],
  ["アママイコ", 52, 40, 48, 40, 48, 62],
  ["アマージョ", 72, 120, 98, 50, 98, 72],
  ["キュワワー", 51, 52, 90, 82, 110, 100],
  ["ヤレユータン", 90, 60, 80, 90, 110, 60],
  ["ナゲツケサル", 100, 120, 90, 40, 60, 80],
  ["スナバァ", 55, 55, 80, 70, 45, 15],
  ["シロデスナ", 85, 75, 110, 100, 75, 35],
  ["メテノ(りゅうせい)", 60, 60, 100, 60, 100, 60],
  ["メテノ(コア)", 60, 100, 60, 100, 60, 120],
  ["ネッコアラ", 65, 115, 65, 75, 95, 65],
  ["ミミッキュ", 55, 90, 80, 50, 105, 96],
  ["ハギギシリ", 68, 105, 70, 70, 70, 92],
  ["ジャラコ", 45, 55, 65, 45, 45, 45],
  ["ジャランゴ", 55, 75, 90, 65, 70, 65],
  ["ジャラランガ", 75, 110, 125, 100, 105, 85],
  ["コスモッグ", 43, 29, 31, 29, 31, 37],
  ["コスモウム", 43, 29, 131, 29, 131, 37],
  ["ソルガレオ", 137, 137, 107, 113, 89, 97],
  ["ルナアーラ", 137, 113, 89, 137, 107, 97],
  ["ネクロズマ(通常)", 97, 107, 101, 127, 89, 79],
  ["ネクロズマ(日食)", 97, 157, 127, 113, 109, 77],
  ["ネクロズマ(月食)", 97, 113, 109, 157, 127, 77],
  ["マギアナ", 80, 95, 115, 130, 115, 65],
  ["サルノリ", 50, 65, 50, 40, 40, 65],
  ["バチンキー", 70, 85, 70, 55, 60, 80],
  ["ゴリランダー", 100, 125, 90, 60, 70, 85],
  ["ヒバニー", 50, 71, 40, 40, 40, 69],
  ["ラビフット", 65, 86, 60, 55, 60, 94],
  ["エースバーン", 80, 116, 75, 65, 75, 119],
  ["メッソン", 50, 40, 40, 70, 40, 70],
  ["ジメレオン", 65, 60, 55, 95, 55, 90],
  ["インテレオン", 70, 85, 65, 125, 65, 120],
  ["ホシガリス", 70, 55, 55, 35, 35, 25],
  ["ヨクバリス", 120, 95, 95, 55, 75, 20],
  ["ココガラ", 38, 47, 35, 33, 35, 57],
  ["アオガラス", 68, 67, 55, 43, 55, 77],
  ["アーマーガア", 98, 87, 105, 53, 85, 67],
  ["カムカメ", 50, 64, 50, 38, 38, 44],
  ["カジリガメ", 90, 115, 90, 48, 68, 74],
  ["タンドン", 30, 40, 50, 40, 50, 30],
  ["トロッゴン", 80, 60, 90, 60, 70, 50],
  ["セキタンザン", 110, 80, 120, 80, 90, 30],
  ["カジッチュ", 40, 40, 80, 40, 40, 20],
  ["アップリュー", 70, 110, 80, 95, 60, 70],
  ["タルップル", 110, 85, 80, 100, 80, 30],
  ["スナヘビ", 52, 57, 75, 35, 50, 46],
  ["サダイジャ", 72, 107, 125, 65, 70, 71],
  ["ウッウ", 70, 85, 55, 85, 95, 85],
  ["サシカマス", 41, 63, 40, 40, 30, 66],
  ["カマスジョー", 61, 123, 60, 60, 50, 136],
  ["エレズン", 40, 38, 35, 54, 35, 40],
  ["ストリンダー(ハイ)", 75, 98, 70, 114, 70, 75],
  ["ストリンダー(ロー)", 75, 98, 70, 114, 70, 75],
  ["ヤバチャ", 40, 45, 45, 74, 54, 50],
  ["ポットデス", 60, 65, 65, 134, 114, 70],
  ["ミブリム", 42, 30, 45, 56, 53, 39],
  ["テブリム", 57, 40, 65, 86, 73, 49],
  ["ブリムオン", 57, 90, 95, 136, 103, 29],
  ["ベロバー", 45, 45, 30, 55, 40, 50],
  ["ギモー", 65, 60, 45, 75, 55, 70],
  ["オーロンゲ", 95, 120, 65, 95, 75, 60],
  ["ニャイキング", 70, 110, 100, 50, 60, 50],
  ["マホミル", 45, 40, 40, 50, 61, 34],
  ["マホイップ", 65, 60, 75, 110, 121, 64],
  ["タイレーツ", 65, 100, 100, 70, 60, 75],
  ["バチンウニ", 48, 101, 95, 91, 85, 15],
  ["ユキハミ", 30, 25, 35, 45, 30, 20],
  ["モスノウ", 70, 65, 60, 125, 90, 65],
  ["イシヘンジン", 100, 125, 135, 20, 20, 70],
  ["コオリッポ(アイス)", 75, 80, 110, 65, 90, 50],
  ["コオリッポ(ナイス)", 75, 80, 70, 65, 50, 130],
  ["イエッサン(オス)", 60, 65, 55, 105, 95, 95],
  ["イエッサン(メス)", 70, 55, 65, 95, 105, 85],
  ["モルペコ", 58, 95, 58, 70, 58, 97],
  ["ゾウドウ", 72, 80, 49, 40, 49, 40],
  ["ダイオウドウ", 122, 130, 69, 80, 69, 30],
  ["ジュラルドン", 70, 95, 115, 120, 50, 85],
  ["ドラメシヤ", 28, 60, 30, 40, 30, 82],
  ["ドロンチ", 68, 80, 50, 60, 50, 102],
  ["ドラパルト", 88, 120, 75, 100, 75, 142],
  ["ザシアン(れきせん)", 92, 120, 115, 80, 115, 138],
  ["ザシアン(けんのおう)", 92, 150, 115, 80, 115, 148],
  ["ザマゼンタ(れきせん)", 92, 120, 115, 80, 115, 138],
  ["ザマゼンタ(たてのおう)", 92, 120, 140, 80, 140, 128],
  ["ムゲンダイナ", 140, 85, 95, 145, 95, 130],
  ["ダクマ", 60, 90, 60, 53, 50, 72],
  ["ウーラオス(いちげき)", 100, 130, 100, 63, 60, 97],
  ["ウーラオス(れんげき)", 100, 130, 100, 63, 60, 97],
  ["ザルード", 105, 120, 105, 70, 95, 105],
  ["レジエレキ", 80, 100, 50, 100, 50, 200],
  ["レジドラゴ", 200, 100, 50, 100, 50, 80],
  ["ブリザポス", 100, 145, 130, 65, 110, 30],
  ["レイスポス", 100, 65, 60, 145, 80, 130],
  ["バドレックス(通常)", 100, 80, 80, 80, 80, 80],
  ["バドレックス(はくばじょう)", 100, 165, 150, 85, 130, 50],
  ["バドレックス(こくばじょう)", 100, 85, 80, 165, 100, 150],
  ["アヤシシ", 103, 105, 72, 105, 75, 65],
  ["バサギリ", 70, 135, 95, 45, 70, 85],
  ["ガチグマ(通常)", 130, 140, 105, 45, 80, 50],
  ["ガチグマ(アカツキ)", 113, 70, 120, 135, 65, 52],
  ["イダイトウ(オス)", 120, 112, 65, 80, 75, 78],
  ["イダイトウ(メス)", 120, 92, 65, 100, 75, 78],
  ["オオニューラ", 80, 130, 60, 40, 80, 120],
  ["ハリーマン", 85, 115, 95, 65, 65, 85],
  ["ラブトロス(けしん)", 74, 115, 70, 135, 80, 106],
  ["ラブトロス(れいじゅう)", 74, 115, 110, 135, 100, 46],
  ["ニャオハ", 40, 61, 54, 45, 45, 65],
  ["ニャローテ", 61, 80, 63, 60, 63, 83],
  ["マスカーニャ", 76, 110, 70, 81, 70, 123],
  ["ホゲータ", 67, 45, 59, 63, 40, 36],
  ["アチゲータ", 81, 55, 78, 90, 58, 49],
  ["ラウドボーン", 104, 75, 100, 110, 75, 66],
  ["クワッス", 55, 65, 45, 50, 45, 50],
  ["ウェルカモ", 70, 85, 65, 65, 60, 65],
  ["ウェーニバル", 85, 120, 80, 85, 75, 85],
  ["グルトン", 54, 45, 40, 35, 45, 35],
  ["パフュートン(オス)", 110, 100, 75, 59, 80, 65],
  ["パフュートン(メス)", 115, 90, 70, 59, 90, 65],
  ["タマンチュラ", 35, 41, 45, 29, 40, 20],
  ["ワナイダー", 60, 79, 92, 52, 86, 35],
  ["マメバッタ", 33, 46, 40, 21, 25, 45],
  ["エクスレッグ", 71, 102, 78, 52, 55, 92],
  ["パモ", 45, 50, 20, 40, 25, 60],
  ["パモット", 60, 75, 40, 50, 40, 85],
  ["パーモット", 70, 115, 70, 70, 60, 105],
  ["ワッカネズミ", 50, 50, 45, 40, 45, 75],
  ["イッカネズミ", 74, 75, 70, 65, 75, 111],
  ["パピモッチ", 37, 55, 70, 30, 55, 65],
  ["バウッツェル", 57, 80, 115, 50, 80, 95],
  ["ミニーブ", 41, 35, 45, 58, 51, 30],
  ["オリーニョ", 52, 53, 60, 78, 78, 33],
  ["オリーヴァ", 78, 69, 90, 125, 109, 39],
  ["イキリンコ", 82, 96, 51, 45, 51, 92],
  ["コジオ", 55, 55, 75, 35, 35, 25],
  ["ジオヅム", 60, 60, 100, 35, 65, 35],
  ["キョジオーン", 100, 100, 130, 45, 90, 35],
  ["カルボウ", 40, 50, 40, 50, 40, 35],
  ["グレンアルマ", 85, 60, 100, 125, 80, 75],
  ["ソウブレイズ", 75, 125, 80, 60, 100, 85],
  ["ズピカ", 61, 31, 41, 59, 35, 45],
  ["ハラバリー", 109, 64, 91, 103, 83, 45],
  ["カイデン", 40, 40, 35, 55, 40, 70],
  ["タイカイデン", 70, 70, 60, 105, 60, 125],
  ["オラチフ", 60, 78, 60, 40, 51, 51],
  ["マフィティフ", 80, 120, 90, 60, 70, 85],
  ["シルシュルー", 40, 65, 35, 40, 35, 75],
  ["タギングル", 63, 95, 65, 80, 72, 110],
  ["アノクサ", 40, 65, 30, 45, 35, 60],
  ["アノホラグサ", 55, 115, 70, 80, 70, 90],
  ["ノノクラゲ", 40, 40, 35, 50, 100, 70],
  ["リククラゲ", 80, 70, 65, 80, 120, 100],
  ["ガケガニ", 70, 100, 115, 35, 55, 75],
  ["カプサイジ", 50, 62, 40, 62, 40, 50],
  ["スコヴィラン", 65, 108, 65, 108, 65, 75],
  ["シガロコ", 41, 50, 60, 31, 58, 30],
  ["ベラカス", 75, 50, 85, 115, 100, 45],
  ["ヒラヒナ", 30, 35, 30, 55, 30, 75],
  ["クエスパトラ", 95, 60, 60, 101, 60, 105],
  ["カヌチャン", 50, 45, 45, 35, 64, 58],
  ["ナカヌチャン", 65, 55, 55, 45, 82, 78],
  ["デカヌチャン", 85, 75, 77, 70, 105, 94],
  ["ウミディグダ", 10, 55, 25, 35, 25, 95],
  ["ウミトリオ", 35, 100, 50, 50, 70, 120],
  ["オトシドリ", 70, 103, 85, 60, 85, 82],
  ["ナミイルカ", 70, 45, 40, 45, 40, 75],
  ["イルカマン(ナイーブ)", 100, 70, 72, 53, 62, 100],
  ["イルカマン(マイティ)", 100, 160, 97, 106, 87, 100],
  ["ブロロン", 45, 70, 63, 30, 45, 47],
  ["ブロロローム", 80, 119, 90, 54, 67, 90],
  ["モトトカゲ", 70, 95, 65, 85, 65, 121],
  ["ミミズズ", 70, 85, 145, 60, 55, 65],
  ["キラーメ", 48, 35, 42, 105, 60, 60],
  ["キラフロル", 83, 55, 90, 130, 81, 86],
  ["ボチ", 50, 61, 60, 30, 55, 34],
  ["ハカドッグ", 72, 101, 100, 50, 97, 68],
  ["カラミンゴ", 82, 115, 74, 75, 64, 90],
  ["アルクジラ", 108, 68, 45, 30, 40, 43],
  ["ハルクジラ", 170, 113, 65, 45, 55, 73],
  ["ミガルーサ", 90, 102, 73, 78, 65, 70],
  ["ヘイラッシャ", 150, 100, 115, 65, 65, 35],
  ["シャリタツ", 68, 50, 60, 120, 95, 82],
  ["コノヨザル", 110, 115, 80, 50, 90, 90],
  ["ドオー", 130, 75, 60, 45, 100, 20],
  ["リキキリン", 120, 90, 70, 110, 70, 60],
  ["ノココッチ", 125, 100, 80, 85, 75, 55],
  ["ドドゲザン", 100, 135, 120, 60, 85, 50],
  ["イダイナキバ", 115, 131, 131, 53, 53, 87],
  ["サケブシッポ", 115, 65, 99, 65, 115, 111],
  ["アラブルタケ", 111, 127, 99, 79, 99, 55],
  ["ハバタクカミ", 55, 55, 55, 135, 135, 135],
  ["チヲハウハネ", 85, 135, 79, 85, 105, 81],
  ["スナノケガワ", 85, 81, 97, 121, 85, 101],
  ["テツノワダチ", 90, 112, 120, 72, 70, 106],
  ["テツノツツミ", 56, 80, 114, 124, 60, 136],
  ["テツノカイナ", 154, 140, 108, 50, 68, 50],
  ["テツノコウベ", 94, 80, 86, 122, 80, 108],
  ["テツノドクガ", 80, 70, 60, 140, 110, 110],
  ["テツノイバラ", 100, 134, 110, 70, 84, 72],
  ["セビエ", 65, 75, 45, 35, 45, 55],
  ["セゴール", 90, 95, 66, 45, 65, 62],
  ["セグレイブ", 115, 145, 92, 75, 86, 87],
  ["コレクレー(はこ)", 45, 30, 70, 75, 70, 10],
  ["サーフゴー", 87, 60, 95, 133, 91, 84],
  ["チオンジェン", 85, 85, 100, 95, 135, 70],
  ["パオジアン", 80, 120, 80, 90, 65, 135],
  ["ディンルー", 155, 110, 125, 55, 80, 45],
  ["イーユイ", 55, 80, 80, 135, 120, 100],
  ["トドロクツキ", 105, 139, 71, 55, 101, 119],
  ["テツノブジン", 74, 130, 90, 120, 60, 116],
  ["コライドン", 100, 135, 115, 85, 100, 135],
  ["ミライドン", 100, 85, 100, 135, 115, 135],
  ["ウネルミナモ", 99, 83, 91, 125, 83, 109],
  ["テツノイサハ", 90, 130, 88, 70, 108, 104],
  ["カミッチュ", 80, 80, 110, 95, 80, 40],
  ["チャデス", 40, 45, 45, 74, 54, 50],
  ["ヤバソチャ", 71, 60, 106, 121, 80, 70],
  ["イイネイヌ", 88, 128, 115, 58, 86, 80],
  ["マシマシラ", 88, 75, 66, 130, 90, 106],
  ["キチキギス", 88, 91, 82, 70, 125, 99],
  ["オーガポン(みどり)", 80, 120, 84, 60, 96, 110],
  ["オーガポン(いど)", 80, 120, 84, 60, 96, 110],
  ["オーガポン(かまど)", 80, 120, 84, 60, 96, 110],
  ["オーガポン(いしずえ)", 80, 120, 84, 60, 96, 110],
  ["ブリジュラス", 90, 105, 130, 125, 65, 85],
  ["カミツオロチ", 106, 80, 110, 120, 80, 44],
  ["ウガツホムラ", 105, 115, 121, 65, 93, 91],
  ["タケルライコ", 125, 73, 91, 137, 89, 75],
  ["テツノイワオ", 90, 120, 80, 68, 108, 124],
  ["テツノカシラ", 90, 72, 100, 122, 108, 98],
  ["テラパゴス(通常)", 90, 65, 85, 65, 85, 60],
  ["テラパゴス(テラスタル)", 95, 95, 110, 105, 110, 85],
  ["テラパゴス(ステラ)", 160, 105, 110, 130, 110, 85],
  ["モモワロウ", 88, 88, 160, 88, 88, 88],
];

export const pokemon = data.sort((a, b) => (a[0] > b[0] ? 1 : -1));

export const pokemonNames = pokemon.map((d) => d[0]);

export const natureList = [
  "いじっぱり",
  "うっかりや",
  "おくびょう",
  "おだやか",
  "おっとり",
  "おとなしい",
  "がんばりや",
  "きまぐれ",
  "さみしがり",
  "しんちょう",
  "すなお",
  "ずぶとい",
  "せっかち",
  "てれや",
  "なまいき",
  "のうてんき",
  "のんき",
  "ひかえめ",
  "まじめ",
  "むじゃき",
  "やんちゃ",
  "ゆうかん",
  "ようき",
  "れいせい",
  "わんぱく",
];
