<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-app-bar app color="grey darken-1" dark>
      <v-container class="d-flex align-center px-0">
        <v-btn icon @click="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <router-link to="/" class="text-decoration-none">
          <div class="text-sm-h5 text-h6 ml-sm-8 white--text">ヤドンの井戸</div>
        </router-link>

        <v-spacer />

        <v-btn
          class="px-sm-2 px-0"
          href="https://www.mega-yadoran.jp/contact"
          target="_blank"
          text
        >
          <span class="text-sm-body-1 text-caption"
            >バグ報告<span class="mx-sm-2 mx-0">/</span>要望</span
          >
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list dense nav class="mt-4">
        <div class="text-h6 text-center">ヤドンの井戸</div>

        <v-divider class="my-4" />

        <v-list-item v-for="item in menu" :key="item.title" :to="item.href">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-4" />

        <v-list-item href="https://www.mega-yadoran.jp/contact">
          <v-list-item-icon>
            <v-icon>mdi-mail</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>お問い合わせ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-footer>
      <v-row justify="center" no-gutters>
        <v-col cols="12" class="text-center text-subtitle-2">
          2023 —
          <a
            href="https://twitter.com/giga_yadoran"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none"
            >@giga_yadoran</a
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import * as Save from "../utils/save";

export default {
  data: () => ({
    drawer: false,
    menu: [
      { icon: "mdi-home", text: "トップ", href: "/" },
      { icon: "mdi-sword-cross", text: "すばやさ比較", href: "/speed" },
      { icon: "mdi-lead-pencil", text: "パーティ文字起こし", href: "/party" },
      {
        icon: "mdi-cards-playing-heart-multiple-outline",
        text: "パッチール神経衰弱",
        href: "/spinda",
      },
    ],
  }),

  metaInfo: {
    titleTemplate: "%s - ヤドンの井戸",
  },
  async created() {
    // ダブルタップでの拡大を防止
    document.addEventListener(
      "dblclick",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );

    // セーブデータ初期化
    Save.initialize();

    // バージョン確認 & 最新版でなかったら強制リロード
    // dev環境ならスルー
    if (process.env.NODE_ENV === "development") {
      return;
    }
    const isLatest = await this.checkAndUpdateVersion();
    if (!isLatest) {
      return;
    }
  },

  methods: {
    // バージョン確認と更新
    async checkAndUpdateVersion() {
      const response = await axios.get(
        process.env.VUE_APP_VERSION_MANAGER_URL + "/version",
        { params: { app: "poketool" } }
      );
      const version = response.data.version ?? false;
      if (version && version != Save.getVersion()) {
        Save.updateVersion(version);
        window.location.reload();
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.container {
  @media (min-width: 960px) {
    width: 800px;
  }
  .v-btn--active::before {
    display: none;
  }

  .dashed {
    border-style: dashed;
  }

  .v-ripple__container {
    display: none !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
