<template>
  <div>
    <v-card width="90%" max-width="400" class="text-center pa-6 mx-auto mb-4">
      <div class="text-h4 text-center mb-4 success--text font-weight-bold">
        クリア!!
      </div>
      <div class="text-center mb-6">
        難易度<br /><span class="text-h5">{{
          level === 0 ? "かんたん" : "むずかしい"
        }}</span>
      </div>
      <div class="text-center mb-6">
        クリアタイム<br /><span class="text-h5"> {{ counter }} 秒</span>
      </div>
      <v-row justify="center" class="my-2">
        <v-btn color="blue" dark target="_blank" width="120" :href="tweetHref">
          <v-icon class="mr-2">mdi-twitter</v-icon
          ><span class="text-button">TWEET</span>
        </v-btn>
      </v-row>
    </v-card>
    <div class="flex-center">
      <v-radio-group v-model="selectedLevel" row>
        <v-radio label="かんたん" :value="0"></v-radio>
        <v-radio label="むずかしい" :value="1"></v-radio>
      </v-radio-group>
      <v-btn color="success" @click="restart">もう一度</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    counter: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    selectedLevel: null,
  }),
  created() {
    this.selectedLevel = this.level;
  },
  watch: {
    level() {
      this.selectedLevel = this.level;
    },
  },
  computed: {
    levelText() {
      return this.level === 0 ? "かんたん" : "むずかしい";
    },
    tweetHref() {
      const br = "%0D%0A";
      const url = "https://poketool.mega-yadoran.jp/spinda";
      const text = `パッチール神経衰弱${br}難易度「${this.levelText}」を${br}${this.counter}秒でクリアしました！`;
      return `https://twitter.com/intent/tweet?text=${text}${br}${br}${url}${br}&hashtags=パッチール神経衰弱`;
    },
  },
  methods: {
    restart() {
      this.$emit("restart", this.selectedLevel);
    },
  },
};
</script>

<style></style>
