<template>
  <svg
    id="spinda"
    :viewBox="`0 0 ${clip.w * zoom} ${clip.h * zoom}`"
    :width="clip.w * zoom"
    :height="clip.h * zoom"
  >
    <!-- ベース部分 -->
    <g>
      <g>
        <rect x="8" y="0" width="2" height="2" fill="#7b4231"></rect>
        <rect x="10" y="0" width="2" height="2" fill="#7b4231"></rect>
        <rect x="12" y="0" width="2" height="2" fill="#7b4231"></rect>
      </g>
      <g>
        <rect x="6" y="2" width="2" height="2" fill="#7b4231"></rect>
        <rect x="8" y="2" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="2" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="2" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="2" width="2" height="2" fill="#735242"></rect>
        <rect x="16" y="2" width="2" height="2" fill="#7b4231"></rect>
      </g>
      <g>
        <rect x="4" y="4" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="4" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="4" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="4" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="4" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="4" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="4" width="2" height="2" fill="#cea573"></rect>
        <rect x="18" y="4" width="2" height="2" fill="#101010"></rect>
        <rect x="56" y="4" width="2" height="2" fill="#735242"></rect>
        <rect x="58" y="4" width="2" height="2" fill="#101010"></rect>
        <rect x="60" y="4" width="2" height="2" fill="#101010"></rect>
        <rect x="62" y="4" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="6" width="2" height="2" fill="#7b4231"></rect>
        <rect x="4" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="6" width="2" height="2" fill="#cea573"></rect>
        <rect x="20" y="6" width="2" height="2" fill="#101010"></rect>
        <rect x="52" y="6" width="2" height="2" fill="#735242"></rect>
        <rect x="54" y="6" width="2" height="2" fill="#735242"></rect>
        <rect x="56" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="62" y="6" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="64" y="6" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="8" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="8" width="2" height="2" fill="#7b4231"></rect>
        <rect x="12" y="8" width="2" height="2" fill="#101010"></rect>
        <rect x="14" y="8" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="8" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="8" width="2" height="2" fill="#101010"></rect>
        <rect x="52" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="56" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="62" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="64" y="8" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="66" y="8" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="10" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="10" width="2" height="2" fill="#7b4231"></rect>
        <rect x="10" y="10" width="2" height="2" fill="#7b4231"></rect>
        <rect x="12" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="10" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="10" width="2" height="2" fill="#cea573"></rect>
        <rect x="22" y="10" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="10" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="56" y="10" width="2" height="2" fill="#101010"></rect>
        <rect x="58" y="10" width="2" height="2" fill="#101010"></rect>
        <rect x="60" y="10" width="2" height="2" fill="#7b4231"></rect>
        <rect x="62" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="64" y="10" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="66" y="10" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="12" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="12" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="12" width="2" height="2" fill="#7b4231"></rect>
        <rect x="18" y="12" width="2" height="2" fill="#7b4231"></rect>
        <rect x="20" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="12" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="12" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="12" width="2" height="2" fill="#101010"></rect>
        <rect x="56" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="12" width="2" height="2" fill="#7b4231"></rect>
        <rect x="62" y="12" width="2" height="2" fill="#7b4231"></rect>
        <rect x="64" y="12" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="66" y="12" width="2" height="2" fill="#cea573"></rect>
        <rect x="68" y="12" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="14" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="14" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="14" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="14" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="14" width="2" height="2" fill="#735242"></rect>
        <rect x="48" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="14" width="2" height="2" fill="#101010"></rect>
        <rect x="54" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="56" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="62" y="14" width="2" height="2" fill="#101010"></rect>
        <rect x="64" y="14" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="66" y="14" width="2" height="2" fill="#cea573"></rect>
        <rect x="68" y="14" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="16" width="2" height="2" fill="#7b4231"></rect>
        <rect x="10" y="16" width="2" height="2" fill="#7b4231"></rect>
        <rect x="12" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="16" width="2" height="2" fill="#7b4231"></rect>
        <rect x="16" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="16" width="2" height="2" fill="#cea573"></rect>
        <rect x="24" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="54" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="56" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="58" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="16" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="62" y="16" width="2" height="2" fill="#101010"></rect>
        <rect x="64" y="16" width="2" height="2" fill="#cea573"></rect>
        <rect x="66" y="16" width="2" height="2" fill="#cea573"></rect>
        <rect x="68" y="16" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="18" width="2" height="2" fill="#7b4231"></rect>
        <rect x="20" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="18" width="2" height="2" fill="#7b4231"></rect>
        <rect x="54" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="56" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="18" width="2" height="2" fill="#101010"></rect>
        <rect x="62" y="18" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="64" y="18" width="2" height="2" fill="#cea573"></rect>
        <rect x="66" y="18" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="20" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="20" width="2" height="2" fill="#101010"></rect>
        <rect x="14" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="20" width="2" height="2" fill="#101010"></rect>
        <rect x="44" y="20" width="2" height="2" fill="#735242"></rect>
        <rect x="46" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="56" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="20" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="60" y="20" width="2" height="2" fill="#101010"></rect>
        <rect x="62" y="20" width="2" height="2" fill="#cea573"></rect>
        <rect x="64" y="20" width="2" height="2" fill="#cea573"></rect>
        <rect x="66" y="20" width="2" height="2" fill="#735242"></rect>
      </g>
      <g>
        <rect x="6" y="22" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="22" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="22" width="2" height="2" fill="#101010"></rect>
        <rect x="28" y="22" width="2" height="2" fill="#9c845a"></rect>
        <rect x="30" y="22" width="2" height="2" fill="#9c845a"></rect>
        <rect x="32" y="22" width="2" height="2" fill="#9c845a"></rect>
        <rect x="34" y="22" width="2" height="2" fill="#9c845a"></rect>
        <rect x="36" y="22" width="2" height="2" fill="#9c845a"></rect>
        <rect x="38" y="22" width="2" height="2" fill="#9c845a"></rect>
        <rect x="44" y="22" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="56" y="22" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="58" y="22" width="2" height="2" fill="#101010"></rect>
        <rect x="60" y="22" width="2" height="2" fill="#cea573"></rect>
        <rect x="62" y="22" width="2" height="2" fill="#cea573"></rect>
        <rect x="64" y="22" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="8" y="24" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="24" width="2" height="2" fill="#7b4231"></rect>
        <rect x="18" y="24" width="2" height="2" fill="#7b4231"></rect>
        <rect x="20" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="24" width="2" height="2" fill="#735242"></rect>
        <rect x="26" y="24" width="2" height="2" fill="#101010"></rect>
        <rect x="28" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="24" width="2" height="2" fill="#101010"></rect>
        <rect x="42" y="24" width="2" height="2" fill="#735242"></rect>
        <rect x="44" y="24" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="24" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="24" width="2" height="2" fill="#101010"></rect>
        <rect x="56" y="24" width="2" height="2" fill="#7b4231"></rect>
        <rect x="58" y="24" width="2" height="2" fill="#cea573"></rect>
        <rect x="60" y="24" width="2" height="2" fill="#cea573"></rect>
        <rect x="62" y="24" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="10" y="26" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="26" width="2" height="2" fill="#101010"></rect>
        <rect x="14" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="26" width="2" height="2" fill="#735242"></rect>
        <rect x="46" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="26" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="26" width="2" height="2" fill="#7b4231"></rect>
        <rect x="52" y="26" width="2" height="2" fill="#7b4231"></rect>
        <rect x="54" y="26" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="26" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="26" width="2" height="2" fill="#101010"></rect>
        <rect x="60" y="26" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="14" y="28" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="28" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="28" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="28" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="28" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="28" width="2" height="2" fill="#101010"></rect>
        <rect x="56" y="28" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="12" y="30" width="2" height="2" fill="#735242"></rect>
        <rect x="14" y="30" width="2" height="2" fill="#cea573"></rect>
        <rect x="16" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="30" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="30" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="30" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="10" y="32" width="2" height="2" fill="#9c845a"></rect>
        <rect x="12" y="32" width="2" height="2" fill="#cea573"></rect>
        <rect x="14" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="32" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="32" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="10" y="34" width="2" height="2" fill="#735242"></rect>
        <rect x="12" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="34" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="34" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="34" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="8" y="36" width="2" height="2" fill="#735242"></rect>
        <rect x="10" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="36" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="36" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="36" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="8" y="38" width="2" height="2" fill="#735242"></rect>
        <rect x="10" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="38" width="2" height="2" fill="#7b4231"></rect>
        <rect x="16" y="38" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="38" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="38" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="38" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="38" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="40" width="2" height="2" fill="#735242"></rect>
        <rect x="8" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="40" width="2" height="2" fill="#7b4231"></rect>
        <rect x="14" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="40" width="2" height="2" fill="#7b4231"></rect>
        <rect x="22" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="40" width="2" height="2" fill="#7b4231"></rect>
        <rect x="40" y="40" width="2" height="2" fill="#101010"></rect>
        <rect x="42" y="40" width="2" height="2" fill="#101010"></rect>
        <rect x="44" y="40" width="2" height="2" fill="#7b4231"></rect>
        <rect x="46" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="40" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="40" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="40" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="40" width="2" height="2" fill="#735242"></rect>
      </g>
      <g>
        <rect x="6" y="42" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="42" width="2" height="2" fill="#7b4231"></rect>
        <rect x="12" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="42" width="2" height="2" fill="#7b4231"></rect>
        <rect x="18" y="42" width="2" height="2" fill="#7b4231"></rect>
        <rect x="20" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="42" width="2" height="2" fill="#101010"></rect>
        <rect x="24" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="42" width="2" height="2" fill="#101010"></rect>
        <rect x="38" y="42" width="2" height="2" fill="#7b4231"></rect>
        <rect x="40" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="42" width="2" height="2" fill="#7b4231"></rect>
        <rect x="48" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="50" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="42" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="54" y="42" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="42" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="42" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="44" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="44" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="44" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="44" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="44" width="2" height="2" fill="#101010"></rect>
        <rect x="24" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="44" width="2" height="2" fill="#7b4231"></rect>
        <rect x="36" y="44" width="2" height="2" fill="#7b4231"></rect>
        <rect x="38" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="44" width="2" height="2" fill="#7b4231"></rect>
        <rect x="42" y="44" width="2" height="2" fill="#7b4231"></rect>
        <rect x="44" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="44" width="2" height="2" fill="#7b4231"></rect>
        <rect x="50" y="44" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="44" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="44" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="44" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="44" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="24" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="36" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="40" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="46" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="46" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="52" y="46" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="46" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="46" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="46" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="48" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="48" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="48" width="2" height="2" fill="#7b4231"></rect>
        <rect x="16" y="48" width="2" height="2" fill="#7b4231"></rect>
        <rect x="18" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="48" width="2" height="2" fill="#7b4231"></rect>
        <rect x="22" y="48" width="2" height="2" fill="#7b4231"></rect>
        <rect x="24" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="48" width="2" height="2" fill="#101010"></rect>
        <rect x="36" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="48" width="2" height="2" fill="#7b4231"></rect>
        <rect x="40" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="44" y="48" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="48" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="48" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="48" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="48" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="48" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="48" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="48" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="50" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="50" width="2" height="2" fill="#cea573"></rect>
        <rect x="10" y="50" width="2" height="2" fill="#7b4231"></rect>
        <rect x="12" y="50" width="2" height="2" fill="#7b4231"></rect>
        <rect x="14" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="50" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="50" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="50" width="2" height="2" fill="#101010"></rect>
        <rect x="22" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="50" width="2" height="2" fill="#7b4231"></rect>
        <rect x="38" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="50" width="2" height="2" fill="#735242"></rect>
        <rect x="44" y="50" width="2" height="2" fill="#7b4231"></rect>
        <rect x="46" y="50" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="48" y="50" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="50" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="50" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="50" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="50" width="2" height="2" fill="#cea573"></rect>
        <rect x="58" y="50" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="8" y="52" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="52" width="2" height="2" fill="#cea573"></rect>
        <rect x="12" y="52" width="2" height="2" fill="#101010"></rect>
        <rect x="14" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="52" width="2" height="2" fill="#7b4231"></rect>
        <rect x="28" y="52" width="2" height="2" fill="#101010"></rect>
        <rect x="30" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="52" width="2" height="2" fill="#101010"></rect>
        <rect x="40" y="52" width="2" height="2" fill="#101010"></rect>
        <rect x="42" y="52" width="2" height="2" fill="#101010"></rect>
        <rect x="44" y="52" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="46" y="52" width="2" height="2" fill="#7b4231"></rect>
        <rect x="48" y="52" width="2" height="2" fill="#7b4231"></rect>
        <rect x="50" y="52" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="52" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="52" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="52" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="8" y="54" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="12" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="14" y="54" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="18" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="38" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="40" y="54" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="42" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="44" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="46" y="54" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="50" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="54" width="2" height="2" fill="#cea573"></rect>
        <rect x="56" y="54" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="10" y="56" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="14" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="16" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="18" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="20" y="56" width="2" height="2" fill="#735242"></rect>
        <rect x="22" y="56" width="2" height="2" fill="#101010"></rect>
        <rect x="24" y="56" width="2" height="2" fill="#735242"></rect>
        <rect x="26" y="56" width="2" height="2" fill="#735242"></rect>
        <rect x="28" y="56" width="2" height="2" fill="#735242"></rect>
        <rect x="30" y="56" width="2" height="2" fill="#735242"></rect>
        <rect x="32" y="56" width="2" height="2" fill="#101010"></rect>
        <rect x="34" y="56" width="2" height="2" fill="#735242"></rect>
        <rect x="36" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="40" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="42" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="44" y="56" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="48" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="50" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="56" width="2" height="2" fill="#cea573"></rect>
        <rect x="54" y="56" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="58" width="2" height="2" fill="#735242"></rect>
        <rect x="4" y="58" width="2" height="2" fill="#101010"></rect>
        <rect x="12" y="58" width="2" height="2" fill="#101010"></rect>
        <rect x="14" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="16" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="18" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="20" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="22" y="58" width="2" height="2" fill="#e76373"></rect>
        <rect x="24" y="58" width="2" height="2" fill="#e76373"></rect>
        <rect x="26" y="58" width="2" height="2" fill="#e76373"></rect>
        <rect x="28" y="58" width="2" height="2" fill="#e76373"></rect>
        <rect x="30" y="58" width="2" height="2" fill="#e76373"></rect>
        <rect x="32" y="58" width="2" height="2" fill="#735242"></rect>
        <rect x="34" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="40" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="42" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="44" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="46" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="48" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="50" y="58" width="2" height="2" fill="#cea573"></rect>
        <rect x="52" y="58" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="0" y="60" width="2" height="2" fill="#735242"></rect>
        <rect x="2" y="60" width="2" height="2" fill="#ef524a"></rect>
        <rect x="4" y="60" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="60" width="2" height="2" fill="#7b4231"></rect>
        <rect x="8" y="60" width="2" height="2" fill="#7b4231"></rect>
        <rect x="10" y="60" width="2" height="2" fill="#7b4231"></rect>
        <rect x="14" y="60" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="18" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="20" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="22" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="24" y="60" width="2" height="2" fill="#735242"></rect>
        <rect x="26" y="60" width="2" height="2" fill="#101010"></rect>
        <rect x="28" y="60" width="2" height="2" fill="#101010"></rect>
        <rect x="30" y="60" width="2" height="2" fill="#735242"></rect>
        <rect x="32" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="40" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="42" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="44" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="46" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="48" y="60" width="2" height="2" fill="#cea573"></rect>
        <rect x="50" y="60" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="0" y="62" width="2" height="2" fill="#101010"></rect>
        <rect x="2" y="62" width="2" height="2" fill="#ef524a"></rect>
        <rect x="4" y="62" width="2" height="2" fill="#ef524a"></rect>
        <rect x="6" y="62" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="62" width="2" height="2" fill="#ef524a"></rect>
        <rect x="10" y="62" width="2" height="2" fill="#ef524a"></rect>
        <rect x="12" y="62" width="2" height="2" fill="#735242"></rect>
        <rect x="14" y="62" width="2" height="2" fill="#735242"></rect>
        <rect x="16" y="62" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="62" width="2" height="2" fill="#735242"></rect>
        <rect x="20" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="22" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="24" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="26" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="40" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="42" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="44" y="62" width="2" height="2" fill="#cea573"></rect>
        <rect x="46" y="62" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="62" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="0" y="64" width="2" height="2" fill="#101010"></rect>
        <rect x="2" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="4" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="6" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="10" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="12" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="14" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="16" y="64" width="2" height="2" fill="#ef524a"></rect>
        <rect x="18" y="64" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="64" width="2" height="2" fill="#101010"></rect>
        <rect x="22" y="64" width="2" height="2" fill="#735242"></rect>
        <rect x="24" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="26" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="64" width="2" height="2" fill="#cea573"></rect>
        <rect x="40" y="64" width="2" height="2" fill="#101010"></rect>
        <rect x="42" y="64" width="2" height="2" fill="#101010"></rect>
        <rect x="44" y="64" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="66" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="66" width="2" height="2" fill="#ef524a"></rect>
        <rect x="6" y="66" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="66" width="2" height="2" fill="#ef524a"></rect>
        <rect x="10" y="66" width="2" height="2" fill="#ef524a"></rect>
        <rect x="12" y="66" width="2" height="2" fill="#ef524a"></rect>
        <rect x="14" y="66" width="2" height="2" fill="#735242"></rect>
        <rect x="16" y="66" width="2" height="2" fill="#735242"></rect>
        <rect x="18" y="66" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="66" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="66" width="2" height="2" fill="#cea573"></rect>
        <rect x="24" y="66" width="2" height="2" fill="#cea573"></rect>
        <rect x="26" y="66" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="66" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="66" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="66" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="66" width="2" height="2" fill="#735242"></rect>
        <rect x="36" y="66" width="2" height="2" fill="#101010"></rect>
        <rect x="38" y="66" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="68" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="68" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="68" width="2" height="2" fill="#ef524a"></rect>
        <rect x="10" y="68" width="2" height="2" fill="#ef524a"></rect>
        <rect x="12" y="68" width="2" height="2" fill="#735242"></rect>
        <rect x="14" y="68" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="68" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="68" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="68" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="68" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="26" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="68" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="68" width="2" height="2" fill="#735242"></rect>
        <rect x="40" y="68" width="2" height="2" fill="#735242"></rect>
      </g>
      <g>
        <rect x="8" y="70" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="70" width="2" height="2" fill="#735242"></rect>
        <rect x="12" y="70" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="70" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="70" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="18" y="70" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="20" y="70" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="22" y="70" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="24" y="70" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="70" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="70" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="70" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="70" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="70" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="70" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="70" width="2" height="2" fill="#ef524a"></rect>
        <rect x="40" y="70" width="2" height="2" fill="#ef524a"></rect>
        <rect x="42" y="70" width="2" height="2" fill="#101010"></rect>
        <rect x="44" y="70" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="70" width="2" height="2" fill="#735242"></rect>
      </g>
      <g>
        <rect x="8" y="72" width="2" height="2" fill="#735242"></rect>
        <rect x="10" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="72" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="16" y="72" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="18" y="72" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="20" y="72" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="22" y="72" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="24" y="72" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="26" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="72" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="72" width="2" height="2" fill="#ef947b"></rect>
        <rect x="38" y="72" width="2" height="2" fill="#ef524a"></rect>
        <rect x="40" y="72" width="2" height="2" fill="#ef524a"></rect>
        <rect x="42" y="72" width="2" height="2" fill="#ef524a"></rect>
        <rect x="44" y="72" width="2" height="2" fill="#ef524a"></rect>
        <rect x="46" y="72" width="2" height="2" fill="#ef524a"></rect>
        <rect x="48" y="72" width="2" height="2" fill="#101010"></rect>
        <rect x="50" y="72" width="2" height="2" fill="#735242"></rect>
        <rect x="52" y="72" width="2" height="2" fill="#735242"></rect>
      </g>
      <g>
        <rect x="6" y="74" width="2" height="2" fill="#735242"></rect>
        <rect x="8" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="74" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="16" y="74" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="18" y="74" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="20" y="74" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="22" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="74" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="40" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="42" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="44" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="46" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="48" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="50" y="74" width="2" height="2" fill="#ef524a"></rect>
        <rect x="52" y="74" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="76" width="2" height="2" fill="#735242"></rect>
        <rect x="8" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="76" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="18" y="76" width="2" height="2" fill="#f7efbd"></rect>
        <rect x="20" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="76" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="36" y="76" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="76" width="2" height="2" fill="#ef524a"></rect>
        <rect x="40" y="76" width="2" height="2" fill="#ef524a"></rect>
        <rect x="42" y="76" width="2" height="2" fill="#ef524a"></rect>
        <rect x="44" y="76" width="2" height="2" fill="#ef524a"></rect>
        <rect x="46" y="76" width="2" height="2" fill="#ef524a"></rect>
        <rect x="48" y="76" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="50" y="76" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="52" y="76" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="78" width="2" height="2" fill="#735242"></rect>
        <rect x="6" y="78" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="78" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="78" width="2" height="2" fill="#ef947b"></rect>
        <rect x="36" y="78" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="78" width="2" height="2" fill="#ef524a"></rect>
        <rect x="40" y="78" width="2" height="2" fill="#ef524a"></rect>
        <rect x="42" y="78" width="2" height="2" fill="#ef524a"></rect>
        <rect x="44" y="78" width="2" height="2" fill="#ef524a"></rect>
        <rect x="46" y="78" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="48" y="78" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="50" y="78" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="80" width="2" height="2" fill="#735242"></rect>
        <rect x="6" y="80" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="80" width="2" height="2" fill="#ef524a"></rect>
        <rect x="10" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="32" y="80" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="34" y="80" width="2" height="2" fill="#ef524a"></rect>
        <rect x="36" y="80" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="80" width="2" height="2" fill="#ef524a"></rect>
        <rect x="40" y="80" width="2" height="2" fill="#101010"></rect>
        <rect x="42" y="80" width="2" height="2" fill="#101010"></rect>
        <rect x="44" y="80" width="2" height="2" fill="#101010"></rect>
        <rect x="46" y="80" width="2" height="2" fill="#101010"></rect>
        <rect x="48" y="80" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="82" width="2" height="2" fill="#735242"></rect>
        <rect x="6" y="82" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="82" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="82" width="2" height="2" fill="#735242"></rect>
        <rect x="12" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="16" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="28" y="82" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="30" y="82" width="2" height="2" fill="#ef947b"></rect>
        <rect x="32" y="82" width="2" height="2" fill="#ef524a"></rect>
        <rect x="34" y="82" width="2" height="2" fill="#ef524a"></rect>
        <rect x="36" y="82" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="82" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="40" y="82" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="84" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="84" width="2" height="2" fill="#735242"></rect>
        <rect x="14" y="84" width="2" height="2" fill="#ef524a"></rect>
        <rect x="16" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="18" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="20" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="22" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="24" y="84" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="26" y="84" width="2" height="2" fill="#ef947b"></rect>
        <rect x="28" y="84" width="2" height="2" fill="#ef524a"></rect>
        <rect x="30" y="84" width="2" height="2" fill="#ef524a"></rect>
        <rect x="32" y="84" width="2" height="2" fill="#ef524a"></rect>
        <rect x="34" y="84" width="2" height="2" fill="#ef524a"></rect>
        <rect x="36" y="84" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="84" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="40" y="84" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="86" width="2" height="2" fill="#735242"></rect>
        <rect x="4" y="86" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="86" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="8" y="86" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="10" y="86" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="86" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="86" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="16" y="86" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="18" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="20" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="22" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="24" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="26" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="28" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="30" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="32" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="34" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="36" y="86" width="2" height="2" fill="#ef524a"></rect>
        <rect x="38" y="86" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="40" y="86" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="88" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="88" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="6" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="88" width="2" height="2" fill="#cea573"></rect>
        <rect x="10" y="88" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="12" y="88" width="2" height="2" fill="#e7d6a5"></rect>
        <rect x="14" y="88" width="2" height="2" fill="#cea573"></rect>
        <rect x="16" y="88" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="18" y="88" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="20" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="22" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="24" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="26" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="28" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="30" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="32" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="34" y="88" width="2" height="2" fill="#ef524a"></rect>
        <rect x="36" y="88" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="38" y="88" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="40" y="88" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="2" y="90" width="2" height="2" fill="#101010"></rect>
        <rect x="4" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="6" y="90" width="2" height="2" fill="#ef524a"></rect>
        <rect x="8" y="90" width="2" height="2" fill="#ef524a"></rect>
        <rect x="10" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="12" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="14" y="90" width="2" height="2" fill="#101010"></rect>
        <rect x="16" y="90" width="2" height="2" fill="#101010"></rect>
        <rect x="18" y="90" width="2" height="2" fill="#101010"></rect>
        <rect x="20" y="90" width="2" height="2" fill="#101010"></rect>
        <rect x="22" y="90" width="2" height="2" fill="#ef524a"></rect>
        <rect x="24" y="90" width="2" height="2" fill="#ef524a"></rect>
        <rect x="26" y="90" width="2" height="2" fill="#ef524a"></rect>
        <rect x="28" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="90" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="90" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="38" y="90" width="2" height="2" fill="#bd4a31"></rect>
        <rect x="40" y="90" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="4" y="92" width="2" height="2" fill="#101010"></rect>
        <rect x="6" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="8" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="10" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="12" y="92" width="2" height="2" fill="#101010"></rect>
        <rect x="22" y="92" width="2" height="2" fill="#101010"></rect>
        <rect x="24" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="26" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="92" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="92" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="6" y="94" width="2" height="2" fill="#101010"></rect>
        <rect x="8" y="94" width="2" height="2" fill="#101010"></rect>
        <rect x="10" y="94" width="2" height="2" fill="#101010"></rect>
        <rect x="24" y="94" width="2" height="2" fill="#735242"></rect>
        <rect x="26" y="94" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="94" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="94" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="94" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="94" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="94" width="2" height="2" fill="#cea573"></rect>
        <rect x="38" y="94" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="24" y="96" width="2" height="2" fill="#735242"></rect>
        <rect x="26" y="96" width="2" height="2" fill="#cea573"></rect>
        <rect x="28" y="96" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="96" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="96" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="96" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="96" width="2" height="2" fill="#735242"></rect>
        <rect x="38" y="96" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="26" y="98" width="2" height="2" fill="#735242"></rect>
        <rect x="28" y="98" width="2" height="2" fill="#cea573"></rect>
        <rect x="30" y="98" width="2" height="2" fill="#cea573"></rect>
        <rect x="32" y="98" width="2" height="2" fill="#cea573"></rect>
        <rect x="34" y="98" width="2" height="2" fill="#cea573"></rect>
        <rect x="36" y="98" width="2" height="2" fill="#101010"></rect>
      </g>
      <g>
        <rect x="28" y="100" width="2" height="2" fill="#101010"></rect>
        <rect x="30" y="100" width="2" height="2" fill="#101010"></rect>
        <rect x="32" y="100" width="2" height="2" fill="#101010"></rect>
        <rect x="34" y="100" width="2" height="2" fill="#101010"></rect>
      </g>
    </g>

    <!-- ブチ部分 -->
    <g v-for="(spot, i) in spindaSpots" :key="`spot_${i}`">
      <g v-for="(row, j) in spot" :key="`row_${i}_${j}`">
        <rect
          v-for="(col, k) in row"
          :key="`col_${i}_${j}_${k}`"
          :x="(spotPos[i].x + k) * zoom"
          :y="(spotPos[i].y + j) * zoom"
          :width="zoom"
          :height="zoom"
          :fill="convertSpotColor(col, spotPos[i].y + j, spotPos[i].x + k)"
          :fill-opacity="
            convertSpotColor(col, spotPos[i].y + j, spotPos[i].x + k) !== ''
              ? 1
              : 0
          "
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    pid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      spindaSrc: [
        [
          2, 2, 2, 2, 5, 5, 5, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 5, 0, 0, 0, 6, 5, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 7, 0, 0, 0, 0, 0, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 2, 2, 2, 2, 6, 7, 7, 7, 2, 2, 2,
        ],
        [
          2, 5, 0, 0, 0, 0, 0, 0, 0, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 2, 2, 6, 6, 0, 0, 0, 0, 7, 2, 2,
        ],
        [
          2, 7, 0, 0, 0, 5, 7, 7, 0, 0, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 2, 7, 0, 0, 0, 0, 0, 0, 0, 7, 2,
        ],
        [
          2, 7, 0, 0, 5, 5, 0, 0, 7, 0, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 7, 0, 0, 0, 7, 7, 5, 0, 0, 7, 2,
        ],
        [
          2, 7, 0, 0, 7, 0, 0, 0, 5, 5, 0, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2, 7, 0, 0, 7, 0, 0, 5, 5, 0, 1, 7,
        ],
        [
          2, 7, 0, 0, 7, 0, 0, 0, 0, 7, 0, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          6, 0, 0, 7, 0, 0, 0, 0, 7, 0, 1, 7,
        ],
        [
          2, 2, 7, 0, 5, 5, 0, 5, 0, 7, 0, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          7, 0, 0, 7, 0, 7, 0, 0, 7, 1, 1, 7,
        ],
        [
          2, 2, 7, 0, 0, 7, 0, 0, 7, 5, 0, 0, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          7, 0, 0, 5, 7, 0, 0, 7, 0, 1, 7, 2,
        ],
        [
          2, 2, 2, 7, 0, 0, 7, 0, 0, 0, 0, 0, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 6,
          0, 0, 0, 0, 0, 0, 0, 7, 1, 1, 6, 2,
        ],
        [
          2, 2, 2, 7, 0, 0, 0, 7, 0, 0, 0, 0, 7, 2, 8, 8, 8, 8, 8, 8, 2, 2, 7,
          0, 0, 0, 0, 0, 0, 7, 1, 1, 7, 2, 2,
        ],
        [
          2, 2, 2, 2, 7, 0, 0, 0, 5, 5, 0, 0, 6, 7, 0, 0, 0, 0, 0, 0, 7, 6, 7,
          0, 0, 0, 0, 7, 5, 1, 1, 7, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 7, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6,
          0, 0, 5, 5, 1, 1, 7, 7, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 2, 2, 7, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 1, 1, 7, 7, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 2, 6, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 8, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 7, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 1, 7, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 1, 7, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 6, 0, 0, 5, 7, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 1, 7, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 6, 0, 0, 5, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 5, 7, 7, 5,
          0, 0, 0, 0, 1, 1, 6, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 7, 0, 5, 0, 0, 5, 5, 0, 7, 0, 0, 0, 0, 0, 0, 7, 5, 0, 0, 0,
          5, 0, 0, 0, 1, 1, 7, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 0, 0, 0, 0, 5, 5, 0, 5, 5, 0,
          0, 5, 0, 1, 1, 1, 7, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 7, 0, 7, 0, 7, 0, 0, 0, 7, 0, 0, 0, 0, 0, 7, 0, 7, 0, 0, 7,
          0, 7, 0, 1, 1, 1, 7, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 7, 0, 7, 0, 5, 5, 0, 5, 5, 0, 0, 0, 0, 0, 7, 0, 5, 0, 0, 7,
          0, 7, 1, 1, 1, 1, 7, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 7, 1, 5, 5, 0, 7, 7, 7, 0, 0, 0, 0, 0, 0, 0, 5, 0, 0, 6, 5,
          0, 7, 1, 1, 1, 1, 7, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 7, 1, 7, 0, 0, 0, 0, 0, 0, 5, 7, 0, 0, 0, 0, 7, 7, 7, 0,
          5, 5, 1, 1, 1, 7, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 7, 1, 1, 7, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1,
          7, 1, 1, 1, 1, 7, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 7, 1, 1, 1, 1, 6, 7, 6, 6, 6, 6, 7, 6, 1, 1, 1, 1, 7,
          1, 1, 1, 1, 7, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 6, 7, 2, 2, 2, 7, 1, 1, 1, 1, 9, 9, 9, 9, 9, 6, 1, 1, 1, 1, 1, 1,
          1, 1, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          6, 3, 7, 5, 5, 5, 2, 7, 1, 1, 1, 1, 6, 7, 7, 6, 1, 1, 1, 1, 1, 1, 1,
          1, 1, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          7, 3, 3, 3, 3, 3, 6, 6, 7, 6, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          7, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          7, 3, 3, 3, 3, 3, 3, 3, 3, 7, 7, 6, 1, 1, 1, 1, 1, 1, 1, 1, 7, 7, 7,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 7, 3, 3, 3, 3, 3, 6, 6, 0, 0, 1, 1, 1, 1, 1, 1, 6, 7, 7, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 7, 7, 3, 3, 6, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 6, 6, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 7, 6, 0, 0, 10, 10, 10, 10, 0, 0, 1, 1, 1, 1, 1, 3, 3, 7,
          7, 6, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 6, 0, 0, 10, 10, 10, 10, 10, 10, 0, 0, 0, 0, 0, 11, 3, 3,
          3, 3, 3, 7, 6, 6, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 6, 0, 0, 0, 10, 10, 10, 10, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3,
          3, 3, 3, 3, 7, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 6, 0, 0, 0, 0, 10, 10, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 3, 3,
          3, 4, 4, 7, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 6, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 3, 3, 3, 3, 3,
          4, 4, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 6, 3, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 3, 7, 7, 7,
          7, 7, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 6, 7, 7, 6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 3, 3, 3, 4, 7, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 7, 0, 0, 0, 6, 3, 0, 0, 0, 0, 0, 11, 3, 3, 3, 3, 3, 4, 7, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 6, 0, 0, 0, 0, 0, 4, 4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 7, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 7, 0, 3, 1, 0, 0, 1, 4, 4, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 7, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 7, 1, 3, 3, 1, 1, 7, 7, 7, 7, 3, 3, 3, 1, 1, 1, 1, 4, 4, 7, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 7, 1, 1, 1, 7, 2, 2, 2, 2, 7, 1, 1, 1, 1, 1, 1, 1, 7, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 7, 7, 7, 2, 2, 2, 2, 2, 2, 6, 1, 1, 1, 1, 1, 1, 7, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 6, 1, 1, 1, 1, 1, 6, 7, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 6, 1, 1, 1, 1, 7, 2, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
        [
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 7, 7, 7, 7, 2, 2, 2, 2, 2,
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
        ],
      ],
      spindaPalette: [
        "#e7d6a5",
        "#cea573",
        "",
        "#ef524a",
        "#bd4a31",
        "#7b4231",
        "#735242",
        "#101010",
        "#9c845a",
        "#e76373",
        "#f7efbd",
        "#ef947b",
      ],
      spindaW: 80,
      spindaH: 80,
      spindaPixels: 80 * 80,
      spindaSpots: [
        [
          [2, 2, 2, 3, 3, 3, 2, 2, 2],
          [2, 3, 3, 3, 3, 3, 3, 3, 2],
          [3, 3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3, 3],
          [2, 3, 3, 3, 3, 3, 3, 3, 2],
          [2, 2, 2, 3, 3, 3, 2, 2, 2],
        ],
        [
          [2, 2, 3, 3, 3, 2, 2],
          [2, 3, 3, 3, 3, 3, 2],
          [3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3],
          [2, 3, 3, 3, 3, 3, 2],
          [2, 2, 3, 3, 3, 2, 2],
        ],
        [
          [2, 2, 3, 3, 3, 3, 2, 2],
          [2, 3, 3, 3, 3, 3, 3, 2],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [2, 3, 3, 3, 3, 3, 3, 2],
          [2, 2, 3, 3, 3, 3, 2, 2],
        ],
        [
          [2, 2, 3, 3, 3, 3, 2, 2],
          [2, 3, 3, 3, 3, 3, 3, 2],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [3, 3, 3, 3, 3, 3, 3, 3],
          [2, 3, 3, 3, 3, 3, 3, 2],
          [2, 2, 3, 3, 3, 3, 2, 2],
        ],
      ],
      spotPos: [],
      zoom: 2,
      spotsCanvases: [],
      draggingId: null,
      dragX: null,
      dragY: null,
      mouseOvered: false,
      clip: { x: 17, y: 7, w: 35, h: 52 },
      showGrid: false,
    };
  },
  created() {
    // console.log(this.spindaSrc);
    for (let i = 0; i < 4; i++) {
      this.spotPos[i] = this.getSpotPos(i);
      // console.log(this.spotPos[i]);
    }
  },
  methods: {
    getSpotPos(i) {
      const spot = ((i) => {
        switch (i) {
          case 0:
            return { leftMin: 9, topMin: 11, width: 9, height: 10 };
          case 1:
            return { leftMin: -3, topMin: 11, width: 7, height: 9 };
          case 2:
            return { leftMin: 18, topMin: -5, width: 8, height: 8 };
          case 3:
            return { leftMin: -6, topMin: -7, width: 8, height: 8 };
        }
      })(i);
      const x = (this.pid >> (32 - 8 * (i + 1))) & 0xf;
      const y = (this.pid >> (32 - 8 * (i + 1) + 4)) & 0xf;
      return {
        x: spot.leftMin + x,
        y: spot.topMin + y,
        w: spot.width,
        h: spot.height,
        offset_x: x,
        offset_y: y,
      };
    },
    convertSpotColor(spotColor, x, y) {
      if (x < 0 || y < 0) return "";
      const baseColor = this.spindaSrc[x][y];
      // console.log(`spotColor: ${spotColor}, baseColor: ${baseColor}, x: ${x}, y: ${y}`);
      if (spotColor === 2) return "";
      if (baseColor === 1) return this.spindaPalette[4];
      if (baseColor === 0) return this.spindaPalette[3];
      return "";
    },
  },
};
</script>
