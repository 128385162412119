<template>
  <v-dialog v-model="_open" :retain-focus="false" persistent width="300">
    <v-card class="pt-8 pb-4 text-center">
      <v-progress-circular
        indeterminate
        size="50"
        width="6"
        color="grey darken-1"
        class="mb-6"
      />
      <p>
        画像を読み込み中です<br />
        しばらくお待ちください
      </p>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    _open: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("update:open", value);
      },
    },
  },
};
</script>
