<template>
  <v-combobox
    v-model="_target.value"
    :items="items"
    :filter="customFilter"
    :menu-props="menuProps"
    :search-input.sync="searchInput"
    append-icon=""
    solo
    flat
    dense
    hide-details
    auto-select-first
    :type="prefix ? 'number' : 'text'"
    :prefix="prefix"
    :label="placeholder"
    :placeholder="placeholder"
    :background-color="
      _target.error
        ? 'red lighten-4'
        : _target.focused
        ? 'grey lighten-2'
        : _target.value === '' || _target.value === null
        ? 'grey lighten-4'
        : 'teal lighten-5'
    "
    class="my-1 ml"
    :class="prefix ? 'right-position' : ''"
    ref="inputField"
    @focus="
      (e) => {
        _target.focused = true;
        eliminateSpace();
        // e.target.select();
      }
    "
    @blur="
      (e) => {
        _target.focused = false;
        if (_target.error) {
          appendSpace();
        }
      }
    "
  />
</template>

<script>
import { convert2katakana } from "../../../../utils/kana";
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsWithKana: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    target: {
      type: Object,
      default: () => ({
        value: "",
        focused: false,
      }),
    },
  },

  data: () => ({
    searchInput: "",
  }),

  computed: {
    _target: {
      get() {
        return this.target;
      },
      set(value) {
        this.$emit("update:target", value);
      },
    },
    menuProps() {
      return !this._target.value && !this.searchInput
        ? { value: false }
        : "auto";
    },
  },

  watch: {
    _target: {
      handler() {
        // リストにない名前が入力された場合、エラーにする
        if (!this._target.value) return;
        if (this._target.value.slice(-1) === " ") {
          this._target.error = true;
          return;
        }

        if (
          this._target.value &&
          !this.items.some((item) => item === this._target.value)
        ) {
          this.appendSpace();
          this._target.error = true;
        } else {
          this._target.error = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    focus() {
      this.$refs.inputField.focus();
    },
    customFilter(item, queryText) {
      if (!queryText) return false;

      // 文字列にひらがなやローマ字が含まれる場合、カタカナに変換
      queryText = convert2katakana(queryText);

      // itemにqueryTextが含まれていれば候補に表示
      const itemData = this.itemsWithKana.find((data) => data.name === item);
      return itemData.katakana.indexOf(queryText) > -1;
    },
    appendSpace() {
      // computedより後にするために0.1秒後に実施
      setTimeout(() => {
        if (this._target.error && this._target.value.slice(-1) !== " ") {
          this._target.value += " ";
        }
      }, 100);
    },
    eliminateSpace() {
      if (this.searchInput) {
        this.searchInput = this.searchInput.replace(/\s+/g, "");
      }
    },
  },
};
</script>

<style lang="scss">
.v-text-field__prefix {
  font-size: 12px;
}
.right-position {
  input {
    text-align: right;
  }
}
</style>
